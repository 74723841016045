@charset "UTF-8";

#zmmtg-root {
  z-index: 200; /* mobile navigation overlap */
}
/* global styles affect zoom styles */
#zmmtg-root .footer {
  padding: unset;
}

.about_title {
  position: absolute;
  bottom: 25px;
  left: 35px;
  color: white;
  font-family: 'Lato', Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 900;
  letter-spacing: 1px;
  font-size: 3em;
}

.about_container {
  color: #333;
  font-family: 'Avenir';
  padding-left: 35px;
  padding-right: 35px;
}

@media screen and (max-width: 768px) {
  .about_container {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.about_container h1 {
  font-size: 25px;
  margin-bottom: 20px;
}

@keyframes kapow2 {
  0% {
    width: 30px;
    height: 30px;
    right: 3px;
    top: 3px;
    opacity: 1;
  }
  50% {
    width: 40px;
    height: 40px;
    right: 0px;
    top: 0px;
    opacity: 1;
  }
  100% {
    width: 30px;
    height: 30px;
    right: 3px;
    top: 3px;
    opacity: 0;
  }
}

@keyframes kapow {
  0% {
    width: 35px;
    height: 35px;
    right: 7px;
    top: 7px;
  }
  25% {
    width: 45px;
    height: 45px;
    right: 2px;
    top: 2px;
  }
  100% {
    width: 30px;
    height: 30px;
    right: 10px;
    top: 10px;
  }
}

.heart_fill {
  width: 20px;
  height: 20px;
  top: -1.5px;
}

.user_snapshot_heart {
  width: 90px;
  height: 40px;
  z-index: 200;
  opacity: 1;
  margin-left: 10px;
  margin-top: 30px;
  float: left;
  position: absolute;
  font-size: 1.375rem;
  right: 30px;
  top: 0px;
}

.user_snapshot_heart-expand {
  opacity: 1;
  width: 90px;
  font-size: 24px;
  height: 30px;
  right: 32px;
  top: 0px;
  font-size: 24px;
}
.user_snapshot_heart-expand .heart_fill {
  top: -5px;
  width: 25px;
  height: 25px;
}

.user_snapshot_heart-clicked {
  width: 105px;
  height: 30px;
  right: 17px;
  top: 17px;
  animation: kapow 0.3s forwards;
}

.funnel_sidebar_indent {
  float: left;
  height: 1000px;
  width: 150px;
}

.funnel_sidebar {
  height: 10000px;
  width: 150px;
  position: fixed;
  z-index: 100;
  padding-left: 10px;
  background-color: #f2f2f2;
  border-right: 1px solid #888888;
}
.funnel_sidebar .funnel_sidebar_padding {
  height: 130px;
  width: 100%;
  float: left;
}
.funnel_sidebar .funnel_sidebar_steps {
  width: 150px;
  height: 150px;
  float: left;
  font-family: 'Avenir-Heavy', 'Lato';
  font-weight: 800;
  font-size: 1rem;
  color: black;
  padding-left: 10px;
  padding-top: 20px;
}
.funnel_sidebar .funnel_sidebar_user_title {
  width: 100%;
  height: 50px;
  line-height: 50px;
  color: black;
  font-family: 'Avenir-Heavy', 'Lato';
  font-weight: 800;
  font-size: 1rem;
  float: left;
}
.funnel_sidebar .funnel_sidebar_user_date {
  width: calc(100% - 20px);
  height: 30px;
  color: black;
  line-height: 30px;
  font-family: 'Avenir-Medium', 'Lato';
  font-weight: 500;
  font-size: 1rem;
  float: left;
  padding-left: 20px;
}

.funnel_group {
  display: inline-block;
  float: left;
  border-right: 1px solid #cccccc;
}
.funnel_group .funnel_group_title {
  text-align: center;
  position: fixed;
  width: 100%;
  color: black;
  font-size: 18px;
  background-color: #dddddd;
  z-index: 990;
}
.funnel_group .funnel_slice {
  height: 100%;
  width: 180px;
  float: left;
  position: relative;
}
.funnel_group .funnel_slice .slice_steps {
  width: 150px;
  height: 150px;
  float: left;
  padding-top: 20px;
  font-family: 'Avenir-Heavy', 'Lato';
  font-weight: 800;
  font-size: 1rem;
  color: black;
  padding-left: 10px;
}
.funnel_group .funnel_slice .slice_header_section_indent {
  height: 130px;
  width: 100%;
  position: relative;
  float: left;
}
.funnel_group .funnel_slice .slice_header_section {
  z-index: 50;
  height: 130px;
  border-bottom: 1px solid #888888;
  width: 180px;
  background-color: #f2f2f2;
  position: fixed;
}
.funnel_group .funnel_slice .slice_header_section .slice_ball {
  border-radius: 8px;
  background-color: #444444;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 86px;
  top: 80px;
  float: left;
}
.funnel_group .funnel_slice .slice_header_section .slice_line {
  left: 12px;
  background-color: #444444;
  height: 2px;
  position: absolute;
  left: 0px;
  width: 100%;
  top: 87px;
  float: left;
}
.funnel_group .funnel_slice .slice_header_section .slice_title_section_label {
  color: black;
  width: calc(100% - 40px);
  position: absolute;
  left: 0px;
  float: left;
  top: 0px;
  margin-left: 20px;
  margin-right: 20px;
  height: 100px;
  text-align: left;
  font-family: 'Avenir-Roman', 'Lato';
  font-weight: 400;
  letter-spacing: 1px;
}
.funnel_group .funnel_slice .slice_header_section .slice_title_label {
  color: black;
  width: calc(100% - 40px);
  position: absolute;
  left: 0px;
  float: left;
  top: 40px;
  margin-left: 20px;
  margin-right: 20px;
  height: 100px;
  text-align: center;
  font-family: 'Avenir-Roman', 'Lato';
  font-weight: 400;
  letter-spacing: 1px;
}
.funnel_group .funnel_slice .slice_user_title {
  width: 100%;
  height: 50px;
  float: left;
}
.funnel_group .funnel_slice .slice_percentage_value {
  width: 50%;
  font-family: 'Avenir-Heavy', 'Lato';
  font-weight: 800;
}
.funnel_group .funnel_slice .slice_user_value {
  color: black;
  width: 100%;
  float: left;
  border: 0.5px solid #eeeeee;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-family: 'Avenir-Roman', 'Lato';
  font-weight: 400;
  letter-spacing: 1px;
}

@keyframes fadeoutblack {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}

@keyframes fadeoutblacklight {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

/***********************************************
*	General components use the following classes
************************************************/
.flex_container {
  display: flex;
}

.flex_container--wrap {
  flex-wrap: wrap;
}

/*
	Go back button for components that slide in from the left
 */
.button__go-back {
  font-style: normal;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  border: none;
  font-size: 32px;
  font-family: 'Lato', Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 100;
  float: right;
  margin-top: 10px;
  margin-right: 30px;
  color: #8a8a8a;
}

.button__go-back:hover {
  color: white;
}

/*
	Covers the entire page with opacity, when components slide in from the left
 */
.disable-page {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #010101;
  z-index: 101;
  opacity: 0.8;
  visibility: hidden;
  -webkit-transition: visibility 1.2s, opacity, 1.2s -webkit-transform 1.2s;
  transition: visibility 1.2s, opacity 1.2s, transform 1.2s;
}

/*
	Covers the entire page with white to create a transition effect
 */
.flash-page {
  width: 100%;
  height: 100%;
  position: fixed;
  background: grey;
  z-index: 101;
  opacity: 1;
  visibility: hidden;
  -webkit-transition: visibility 0.15s, opacity, 0.15s -webkit-transform 0.15s;
  transition: visibility 0.15s, opacity 0.15s, transform 0.15s;
  transition-timing-function: ease-in;
}

.bank_account__container {
  width: 100%;
  height: 100vh;
}
.bank_account__container .bank_account_zip_code {
  width: 70%;
  border: 1px solid #8a8a8a;
  margin: auto;
  display: block;
  background: black;
  text-align: center;
  font-size: 0.9em;
  letter-spacing: 1px;
  margin-bottom: 15px;
}
.bank_account__container .bank_account__left_partition {
  width: 50%;
  float: left;
  height: 100%;
  position: relative;
}
.bank_account__container .bank_account__left_partition .bank_account__TOS_text {
  text-align: center;
  color: white;
  font-size: 1rem;
  font-family: 'Avenir-Roman', 'Lato';
  font-weight: 400;
  letter-spacing: 1px;
}
.bank_account__container .bank_account__left_partition .bank_account__TOS_text a {
  text-align: center;
  color: #fa4726;
}
.bank_account__container .bank_account__left_partition .bank_account__back {
  color: #444444;
  font-family: 'Avenir-Medium', 'Lato';
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 40px;
  letter-spacing: 1px;
  width: 60px;
  height: 30px;
  position: absolute;
  left: 40px;
  top: 20px;
  text-align: left;
  cursor: pointer;
}
.bank_account__container .bank_account__left_partition .bank_account__back:hover {
  opacity: 0.7;
}
.bank_account__container .bank_account__left_partition .bank_account__left_partition_content {
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 80px;
}
.bank_account__container .bank_account__right_partition {
  width: 50%;
  float: left;
  height: 100%;
  background-color: #f7f7f7;
}
.bank_account__container .bank_account__right_partition .bank_account__guidance_text {
  margin-left: 50px;
  margin-top: 80px;
  color: #555;
  font-family: 'Avenir-Medium', 'Lato';
  font-weight: 500;
  font-size: 1.25rem;
}
.bank_account__container .bank_account__select {
  border: 1px solid white;
  background-color: black;
  border-radius: 0px;
  width: 200px;
  appearance: none;
  height: 45px;
}
.bank_account__container .bank_account__select:focus {
  outline: none;
}
.bank_account__container .bank_account__title {
  color: black;
  font-family: 'Avenir-Medium', 'Lato';
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 40px;
  letter-spacing: 1px;
  width: 100%;
  margin-top: 20px;
  text-align: center;
}
.bank_account__container .bank_account__error_message {
  width: 100%;
  margin: auto;
  float: left;
  height: 45px;
  letter-spacing: 2px;
  font-family: 'Avenir-Light', 'Lato';
  font-weight: 300;
  text-align: center;
  font-size: 1rem;
  margin-top: 30px;
  color: #e26e34;
}
.bank_account__container .bank_account__option:hover {
  color: white;
  border-color: white;
}
.bank_account__container .bank_account__option {
  width: 100%;
  height: 32px;
  background-color: black;
  text-align: center;
  border: 1px solid #8a8a8a;
  height: 45px;
  cursor: pointer;
  color: #8a8a8a;
  line-height: 45px;
  text-transform: uppercase;
  margin-bottom: 5px;
  letter-spacing: 1px;
}
.bank_account__container .bank_account__input {
  width: 100%;
  background-color: black;
  text-align: center;
  border: 1px solid #8a8a8a;
  height: 45px;
  text-transform: uppercase;
  margin-bottom: 5px;
  letter-spacing: 1px;
}
.bank_account__container .bank_account__input_small_left {
  width: calc(49.5% - 2px);
  background-color: black;
  text-align: center;
  border: 1px solid #8a8a8a;
  height: 45px;
  text-transform: uppercase;
  margin-bottom: 5px;
  letter-spacing: 1px;
  margin-right: 4px;
}
.bank_account__container .bank_account__input_small_right {
  width: calc(49.5% - 2px);
  background-color: black;
  text-align: center;
  border: 1px solid #8a8a8a;
  height: 45px;
  text-transform: uppercase;
  margin-bottom: 5px;
  letter-spacing: 1px;
}
.bank_account__container .bank_account__button {
  margin-top: 20px;
  width: 100%;
  background-color: #fffffe;
  color: black;
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  letter-spacing: 1px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  height: 45px;
  float: left;
}
.bank_account__container .bank_account__button:hover {
  opacity: 0.8;
}
.bank_account__container .bank_account__progress_bar {
  float: left;
  position: relative;
  height: 4px;
  width: 100%;
}
.bank_account__container .bank_account__progress_bar .bank_account__progress_bar_backdrop {
  width: 100%;
  height: 100%;
  background-color: #444444;
  position: absolute;
  top: -1px;
  z-index: 200;
}
.bank_account__container .bank_account__progress_bar .bank_account__progress_bar_fill {
  position: absolute;
  top: -1px;
  z-index: 200;
  background-color: #fa4726;
  height: 100%;
}

.chat_view_emoji_dropdown {
  width: 400px;
  position: absolute;
  height: 200px;
  top: -200px;
  right: 0px;
  background-color: white;
  overflow: scroll;
  border: 1px solid #777777;
}

.chat_view_emoji_icon {
  font-size: 24px;
  width: 40px;
  text-align: center;
  cursor: pointer;
  line-height: 40px;
  height: 40px;
  float: left;
}

.chat_mini_message_block {
  width: 100%;
  float: left;
  position: relative;
  margin-bottom: 15px;
}
.chat_mini_message_block .chat_mini_message_image {
  margin-left: 10px;
  margin-right: 10px;
  background-color: #1a1a1a;
}
.chat_mini_message_block .chat_mini_message_image.chat_mini_message_image--other_user {
  float: left;
}
.chat_mini_message_block .chat_mini_message_image.chat_mini_message_image--me {
  float: right;
}
.chat_mini_message_block .chat_mini_message_block_title {
  color: black;
  width: calc(100% - 200px);
  font-family: 'Avenir-Heavy', 'Lato';
  font-weight: 800;
  height: 20px;
}
.chat_mini_message_block .chat_mini_message_block_title.chat_mini_message_block_title--other_user {
  float: left;
  margin-left: 20px;
}
.chat_mini_message_block .chat_mini_message_block_title.chat_mini_message_block_title--me {
  text-align: right;
  float: right;
  margin-right: 20px;
}
.chat_mini_message_block .chat_mini_message_block_text {
  width: calc(100% - 60px);
  letter-spacing: 0.5px;
  font-size: 13px;
  font-family: 'Avenir-Roman';
}
.chat_mini_message_block .chat_mini_message_block_text.chat_mini_message_block_text--other_user {
  text-align: left;
  color: #444444;
  margin-left: 0px;
  float: left;
}
.chat_mini_message_block .chat_mini_message_block_text.chat_mini_message_block_text--me {
  text-align: right;
  color: #444444;
  margin-right: 0px;
  float: right;
}

.chat_view {
  position: absolute;
  width: 100%;
  height: calc(100% - 50px);
  top: 0px;
  /* if both the vertical and the horizontal bars appear, then perhaps the right bottom corner also needs to be styled */
}
.chat_view ::-webkit-scrollbar {
  background: #111;
  width: 0px;
  height: 0px;
}
.chat_view ::-webkit-scrollbar-corner {
  background-color: black;
}
.chat_view ::-webkit-scrollbar-thumb {
  background: #aaa;
  width: 1px;
}
.chat_view .chat_sidebar .chat_search_container {
  width: 450px;
  position: fixed;
  top: 45px;
  border: 1px solid #bbbbbb;
  height: 40px;
}
.chat_view .chat_sidebar .chat_search_container .chat_search {
  width: 100%;
  color: black;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  background-color: white;
  height: 100%;
  float: left;
  padding-left: 10px;
}
.chat_view .chat_sidebar .chat_sidebar_element.selected {
  background-color: #eeeeee;
}
.chat_view .chat_sidebar .chat_sidebar_element {
  width: 100%;
  background-color: white;
  height: 90px;
  float: left;
  cursor: pointer;
  position: relative;
  border-bottom-style: solid;
  border-bottom-color: #dddddd;
  border-bottom-width: 1px;
}
.chat_view .chat_sidebar .chat_sidebar_element .chat_sidebar_element_img {
  height: 100%;
  position: absolute;
  left: 0px;
  width: 100px;
}
.chat_view .chat_sidebar .chat_sidebar_element .chat_sidebar_element__date {
  position: absolute;
  right: 20px;
  margin-left: 20px;
  margin-top: 10px;
  letter-spacing: 1px;
  font-family: 'Avenir-Roman', 'Lato';
  font-weight: 400;
  letter-spacing: 1px;
  color: gray;
  height: 40px;
  font-size: 14px;
}
.chat_view .chat_sidebar .chat_sidebar_element .chat_sidebar_element__date.selected {
  color: black;
}
.chat_view .chat_sidebar .chat_sidebar_element .chat_sidebar_element__date.unread {
  color: #fa4726;
}
.chat_view .chat_sidebar .chat_sidebar_element .chat_sidebar_element__title {
  position: absolute;
  left: 100px;
  margin-left: 10px;
  margin-top: 10px;
  letter-spacing: 2px;
  font-family: 'Avenir-Black', 'Lato';
  font-weight: 900;
  height: 40px;
  color: black;
}
.chat_view .chat_sidebar .chat_sidebar_element .chat_sidebar_element__title.selected {
  color: black;
}
.chat_view .chat_sidebar .chat_sidebar_element .chat_sidebar_element__title.unread {
  color: #fa4726;
}
.chat_view .chat_sidebar .chat_sidebar_element .chat_sidebar_element__text {
  position: absolute;
  left: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-height: 20px;
  /* fallback */
  max-height: 40px;
  /* fallback */
  margin-right: 20px;
  margin-left: 10px;
  margin-top: 35px;
  letter-spacing: 1px;
  font-family: 'Avenir-Light', 'Lato';
  font-weight: 300;
  height: 40px;
  color: black;
}
.chat_view .chat_sidebar .chat_sidebar_element .chat_sidebar_element__text.unread {
  color: black;
}
.chat_view .chat_sidebar .chat_sidebar_element .chat_sidebar_element__text.selected {
  color: black;
}
.chat_view .chat_sidebar .chat_sidebar_element:hover {
  background-color: #f7f7f7;
}
.chat_view .chat_timestamp {
  width: 100%;
  text-align: center;
  height: 50px;
  float: left;
  line-height: 50px;
  font-family: 'Avenir-Heavy', 'Lato';
  font-weight: 800;
}
.chat_view .chat_header {
  float: left;
  width: 100%;
  height: 60px;
  background-color: white;
  text-align: right;
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #cccccc;
}
.chat_view .chat_header .chat_header_italic {
  font-family: 'Caslon';
  font-size: 1.25rem;
  color: black;
  margin-right: 10px;
  margin-top: 3px;
}
.chat_view .chat_header .chat_header_name {
  font-family: 'Avenir-Black', 'Lato';
  font-weight: 900;
  font-size: 1.875rem;
  letter-spacing: 2px;
  margin-right: 20px;
  color: black;
}
.chat_view .chat_message_view {
  height: calc(100% - 210px);
  width: calc(100% - 450px);
  position: absolute;
  left: 450px;
  float: left;
  background-color: white;
  /* if both the vertical and the horizontal bars appear, then perhaps the right bottom corner also needs to be styled */
}
.chat_view .chat_message_view ::-webkit-scrollbar {
  background-color: #dddddd;
  width: 12px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  height: 0px;
}
.chat_view .chat_message_view ::-webkit-scrollbar-corner {
  border-radius: 5px;
  background-color: #dddddd;
}
.chat_view .chat_message_view ::-webkit-scrollbar-thumb {
  background: #aaa;
  width: 1px;
  border: 2px solid #dddddd;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}
.chat_view .chat_message_view ::-webkit-scrollbar-track {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  width: 1px;
}
.chat_view .chat_message_view .chat_message_container {
  height: 100%;
  width: 100%;
  background-color: white;
  overflow-y: scroll;
}
.chat_view .chat_message_view .chat_message_container .chat_message_block {
  min-height: 100px;
  width: 100%;
  float: left;
  position: relative;
  margin-bottom: 20px;
}
.chat_view .chat_message_view .chat_message_container .chat_message_block .chat_message_image {
  height: 100px;
  width: 100px;
  background-color: #1a1a1a;
  float: left;
  margin-left: 40px;
}
.chat_view .chat_message_view .chat_message_container .chat_message_block .chat_message_block_title {
  color: black;
  width: calc(100% - 300px);
  font-family: 'Avenir-Black', 'Lato';
  font-weight: 900;
  letter-spacing: 1.5px;
  height: 20px;
  float: left;
  margin-left: 20px;
}
.chat_view .chat_message_view .chat_message_container .chat_message_block .chat_message_block_text {
  width: calc(100% - 300px);
  min-height: 40px;
  font-family: 'Avenir-Light', 'Lato';
  font-weight: 300;
  color: black;
  margin-left: 20px;
  float: left;
  letter-spacing: 0.5px;
}
.chat_view
  .chat_message_view
  .chat_message_container
  .chat_message_block
  .chat_message_block_text.chat_message_block_text--other_user {
  text-align: left;
  color: #999999;
  margin-left: 20px;
  float: left;
}
.chat_view
  .chat_message_view
  .chat_message_container
  .chat_message_block
  .chat_message_block_text.chat_message_block_text--me {
  text-align: right;
  color: #999999;
  margin-right: 20px;
  float: right;
}

::-webkit-scrollbar-thumb {
  background: #bbb;
  visibility: hidden;
  width: 8px;
  border-radius: 4px;
}

::-webkit-scrollbar {
  background: #ddd;
  width: 1px;
  visibility: hidden;
  width: 8px;
  border-radius: 4px;
  height: 0px;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
}

::-webkit-scrollbar-corner {
  background-color: #ddd;
  border-radius: 4px;
}

div:hover::-webkit-scrollbar {
  visibility: visible;
  background-color: #ddd;
}

div:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeindelay {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.saved_heart {
  position: absolute;
  right: 10px;
  height: 25px;
  width: 25px;
  top: 6.5px;
  opacity: 0;
}

.saved_heart_activated {
  animation: kapow2 1s;
  opacity: 0;
}

.wishlist_heart {
  opacity: 0;
}

.wishlist_item:hover {
  background-color: #111111;
}

.wishlist_item:hover .wishlist_heart {
  opacity: 1;
}

.modal_background_overlay {
  z-index: 2000;
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  right: 0px;
  min-height: 700px;
  background-color: rgba(10, 10, 10, 0.85);
  animation: fadein 0.3s;
}

.connect_background_overlay {
  z-index: 2000;
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  min-height: 700px;
  background-color: rgba(10, 10, 10, 0.8);
  animation: fadein 0.3s;
}
.connect_background_overlay .connect_container {
  background-color: black;
  width: 450px;
  height: calc(100vh - 200px);
  position: absolute;
  left: calc(50% - 225px);
  top: 100px;
  border: 1px solid #bbbbbb;
}
.connect_background_overlay .connect_container .connect_title {
  font-family: 'Avenir-Medium', 'Lato';
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 1.25rem;
  text-align: center;
  width: 100%;
  pointer-events: none;
  line-height: 60px;
  height: 60px;
  color: #bbbbbb;
}
.connect_background_overlay .connect_container .connect_user_snapshot:hover {
  background-color: #222222;
}
.connect_background_overlay .connect_container .connect_user_snapshot {
  height: 100px;
  width: 100%;
  position: absolute;
  top: 60px;
  border-top: 1px solid #222222;
  border-bottom: 1px solid #222222;
  background-color: #111111;
  float: left;
  cursor: pointer;
}
.connect_background_overlay .connect_container .connect_user_snapshot .connect_user_snapshot_subtitle {
  font-family: 'Avenir-Heavy', 'Lato';
  font-weight: 800;
  letter-spacing: 2px;
  font-size: 12px;
  width: calc(100% - 120px);
  margin-left: 15px;
  margin-top: 5px;
  color: #555555;
  float: left;
}
.connect_background_overlay .connect_container .connect_user_snapshot .connect_user_snapshot_name {
  font-family: 'Avenir-Heavy', 'Lato';
  font-weight: 800;
  letter-spacing: 2px;
  font-size: 14px;
  width: calc(100% - 120px);
  margin-left: 15px;
  margin-top: 15px;
  float: left;
}
.connect_background_overlay .connect_container .connect_content {
  margin-left: 30px;
  margin-right: 30px;
  width: calc(100% - 60px);
  bottom: 0px;
  position: absolute;
  top: 200px;
}
.connect_background_overlay .connect_container .connect_content .connect_input {
  font-family: 'Avenir-Medium', 'Lato';
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1px;
  color: #aaaaaa;
  border: 1px solid white;
  background-color: black;
  color: white;
  float: left;
  width: 100%;
  height: 200px;
  margin-top: 10px;
}
.connect_background_overlay .connect_container .connect_content .connect_reason_title {
  font-family: 'Avenir-Medium', 'Lato';
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1px;
  color: #aaaaaa;
}
.connect_background_overlay .connect_container .connect_content .connect_reason_text {
  font-family: 'Avenir-Roman', 'Lato';
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1px;
}
.connect_background_overlay .connect_container .connect_content .connect_button_section {
  position: absolute;
  width: 100%;
  left: 0%;
  bottom: 30px;
}
.connect_background_overlay .connect_container .connect_content .connect_button_section .connect_button_subtext {
  float: left;
  letter-spacing: 1px;
  font-family: 'Avenir-Roman', 'Lato';
  font-weight: 400;
  text-align: center;
  color: #999999;
}
.connect_background_overlay .connect_container .connect_content .connect_button_section .connect_button_strong:hover {
  background-color: #fa4726;
}
.connect_background_overlay .connect_container .connect_content .connect_button_section .connect_button_weak:hover {
  color: #fa4726;
  border-color: #fa4726;
}
.connect_background_overlay .connect_container .connect_content .connect_button_section .connect_button_strong {
  background-color: #fffffe;
  color: black;
  cursor: pointer;
  border: none;
  margin-top: 20px;
  width: 100%;
  margin-left: 0%;
  float: left;
  padding-bottom: 10px;
  letter-spacing: 1px;
  font-weight: bold;
  text-align: center;
  height: 40px;
  line-height: 40px;
}
.connect_background_overlay .connect_container .connect_content .connect_button_section .connect_button_weak {
  border: 1px solid #fffffe;
  color: white;
  margin-top: 20px;
  width: 100%;
  margin-left: 0%;
  padding-bottom: 10px;
  float: left;
  letter-spacing: 1px;
  font-weight: bold;
  text-align: center;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}

.new_user_profile_button {
  margin-bottom: 5px;
  height: 40px;
  line-height: 40px;
  width: 200px;
  border: 1px solid white;
  float: left;
  font-family: 'Avenir-Light', 'Lato';
  font-weight: 300;
  letter-spacing: 3px;
  font-size: 12px;
  cursor: pointer;
}

.new_user_profile_button_unhoverable {
  margin-bottom: 5px;
  height: 40px;
  line-height: 40px;
  width: 200px;
  border: 1px solid white;
  float: left;
  font-family: 'Avenir-Light', 'Lato';
  font-weight: 300;
  letter-spacing: 3px;
  font-size: 12px;
  cursor: pointer;
}

.new_user_profile_button:hover {
  cursor: pointer;
  border: 1px solid #999999;
  color: #999999;
}

.contacts_title {
  font-family: 'Avenir-Black', 'Lato';
  font-weight: 900;
  letter-spacing: 3px;
  height: 120px;
  line-height: 120px;
  padding-left: 40px;
  width: 100%;
  float: left;
  font-size: 2.125rem;
}

.request_view_request_button {
  height: 35px;
  width: 100%;
  font-family: 'Avenir-Heavy', 'Lato';
  font-weight: 800;
  text-align: center;
  line-height: 35px;
  border: 1px solid white;
  float: left;
}

.create_job_calendar__month {
  width: 350px;
  text-align: center;
  margin: 0 auto;
  height: 100%;
  margin-bottom: 50px;
}
.create_job_calendar__month .create_job_calendar__header {
  font-size: 1.25rem;
  font-family: 'Avenir-Medium', 'Lato';
  font-weight: 500;
  width: 100%;
  line-height: 50px;
  height: 50px;
  text-align: center;
  float: left;
  position: relative;
}
.create_job_calendar__month .create_job_calendar__left_toggle {
  width: 50px;
  height: 50px;
  cursor: pointer;
  left: 0px;
  top: 0px;
  position: absolute;
}
.create_job_calendar__month .create_job_calendar__right_toggle:hover {
  background-color: #222222;
}
.create_job_calendar__month .create_job_calendar__left_toggle:hover {
  background-color: #222222;
}
.create_job_calendar__month .create_job_calendar__right_toggle {
  width: 50px;
  cursor: pointer;
  height: 50px;
  right: 0px;
  top: 0px;
  position: absolute;
}
.create_job_calendar__month .create_job_calendar__day {
  float: left;
  border-color: white;
  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */
  /* Rules below not implemented in browsers yet */
  -o-user-select: none;
  user-select: none;
  border-width: 1px;
  border-style: solid;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
}
.create_job_calendar__month .create_job_calendar__day--inert {
  float: left;
  border-color: #777777;
  color: #777777;
  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */
  /* Rules below not implemented in browsers yet */
  -o-user-select: none;
  user-select: none;
  border-width: 1px;
  border-style: solid;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
}
.create_job_calendar__month .create_job_calendar__day_selected {
  background-color: white;
  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */
  /* Rules below not implemented in browsers yet */
  -o-user-select: none;
  user-select: none;
  color: black;
  float: left;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
}
.create_job_calendar__month .create_job_calendar__day:hover {
  background-color: #222222;
}

.create_job__container {
  width: 100%;
  height: 100%;
}
.create_job__container .create_job__left_partition {
  width: 50%;
  float: left;
  height: 100%;
  position: relative;
  overflow: scroll;
  padding-bottom: 20px;
}
.create_job__container .create_job__left_partition .create_job__back {
  color: #444444;
  font-family: 'Avenir-Medium', 'Lato';
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 40px;
  letter-spacing: 1px;
  width: 60px;
  height: 30px;
  position: absolute;
  left: 40px;
  top: 20px;
  text-align: left;
  cursor: pointer;
}
.create_job__container .create_job__left_partition .create_job__back:hover {
  opacity: 0.7;
}
.create_job__container .create_job__left_partition .create_job__left_partition_content {
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 80px;
}
.create_job__container .create_job__left_partition .create_job__left_partition_content .form-container__form {
  text-align: center;
}
.create_job__container .create_job__left_partition .create_job__left_partition_content h1 {
  text-transform: uppercase;
  font-size: 21px !important;
  letter-spacing: 1px;
  text-align: center;
}
.create_job__container .create_job__right_partition {
  width: 50%;
  float: left;
  height: 100%;
  background-color: #111111;
}
.create_job__container .create_job__right_partition .create_job__guidance_text {
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 80px;
  color: gray;
  font-family: 'Avenir-Medium', 'Lato';
  font-weight: 500;
  font-size: 1.25rem;
}
.create_job__container .create_job__right_partition .create_job__user_container {
  display: flex;
  flex-wrap: wrap;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 20px;
  padding-bottom: 30px;
}
.create_job__container .create_job__verify_container {
  border-bottom: 1px solid #6a6a6a;
}
.create_job__container .create_job__verify_header {
  width: 100%;
  font-family: 'Avenir-Roman', 'Lato';
  font-weight: 400;
  letter-spacing: 1px;
  margin-top: 20px;
  height: 25px;
  color: #8a8a8a;
  text-align: center;
}
.create_job__container .create_job__verify_text {
  width: 100%;
  font-family: 'Avenir-Medium', 'Lato';
  font-weight: 500;
  letter-spacing: 1px;
  text-align: center;
  white-space: pre-line;
}
.create_job__container .create_job__title {
  color: white;
  font-family: 'Avenir-Black', 'Lato';
  font-weight: 500;
  font-size: 21px;
  margin-bottom: 40px;
  letter-spacing: 1.5px;
  width: 100%;
  margin-top: 20px;
  text-align: center;
  text-transform: uppercase;
}
.create_job__container .create_job__subtitle {
  font-size: 1.2em;
}
.create_job__container .create_job__error_message {
  width: 100%;
  margin: auto;
  float: left;
  height: 45px;
  letter-spacing: 2px;
  font-family: 'Avenir-Light', 'Lato';
  font-weight: 300;
  text-align: center;
  font-size: 1rem;
  margin-top: 30px;
  color: #e26e34;
}
.create_job__container .create_job__option:hover {
  color: white;
  border-color: white;
}
.create_job__container .create_job__option {
  width: 100%;
  height: 32px;
  background-color: black;
  text-align: center;
  border: 1px solid #8a8a8a;
  height: 45px;
  cursor: pointer;
  color: #8a8a8a;
  line-height: 45px;
  text-transform: uppercase;
  margin-bottom: 5px;
  letter-spacing: 1px;
}
.create_job__container .create_job__input {
  width: 100%;
  background-color: black;
  text-align: center;
  border: 1px solid #8a8a8a;
  height: 45px;
  text-transform: uppercase;
  margin-bottom: 5px;
  letter-spacing: 1px;
}
.create_job__container .create_job__textarea {
  font-family: 'Avenir-Medium', 'Lato';
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1px;
  color: #aaaaaa;
  border: 1px solid #8a8a8a;
  background-color: black;
  color: white;
  float: left;
  width: 100%;
  height: 200px;
  margin-top: 10px;
  padding-top: 10px;
  padding-left: 10px;
}
.create_job__container .create_job__select {
  border: 1px solid white;
  background-color: black;
  border-radius: 0px;
  width: 200px;
  appearance: none;
  height: 45px;
}
.create_job__container .create_job__select:focus {
  outline: none;
}
.create_job__container .create_job__option:hover {
  color: white;
  border-color: white;
}
.create_job__container .create_job__option {
  width: 100%;
  height: 32px;
  background-color: black;
  text-align: center;
  border: 1px solid #8a8a8a;
  height: 45px;
  cursor: pointer;
  color: #8a8a8a;
  line-height: 45px;
  text-transform: uppercase;
  margin-bottom: 5px;
  letter-spacing: 1px;
}
.create_job__container .create_job__button {
  margin-top: 20px;
  width: 100%;
  background-color: #fffffe;
  color: black;
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  letter-spacing: 1px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  height: 45px;
  float: left;
}
.create_job__container .create_job__button.hollow {
  background-color: black;
  color: white;
  font-weight: normal;
  border: 1px solid white;
}
.create_job__container .create_job__button:hover {
  opacity: 0.8;
}
.create_job__container .create_job__progress_bar {
  float: left;
  position: relative;
  height: 4px;
  width: 100%;
}
.create_job__container .create_job__progress_bar .create_job__progress_bar_backdrop {
  width: 100%;
  height: 100%;
  background-color: #444444;
  position: absolute;
  top: -1px;
  z-index: 200;
}
.create_job__container .create_job__progress_bar .create_job__progress_bar_fill {
  position: absolute;
  top: -1px;
  z-index: 200;
  background-color: #fa4726;
  height: 100%;
}

.croppie-container {
  padding: 30px;
}

.croppie-container .cr-image {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: 0 0;
  max-width: none;
}

.croppie-container .cr-boundary {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  z-index: 1;
}

.croppie-container .cr-viewport {
  position: absolute;
  border: 2px solid #fff;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  box-shadow: 0 0 2000px 2000px rgba(0, 0, 0, 0.5);
  z-index: 0;
}

.croppie-container .cr-vp-circle {
  border-radius: 50%;
}

.croppie-container .cr-overlay {
  z-index: 1;
  position: absolute;
  cursor: move;
}

.croppie-container .cr-slider-wrap {
  width: 75%;
  margin: 0 auto;
  margin-top: 25px;
  text-align: center;
}

.croppie-result {
  position: relative;
  overflow: hidden;
}

.croppie-result img {
  position: absolute;
}

.croppie-container .cr-image,
.croppie-container .cr-overlay,
.croppie-container .cr-viewport {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

/*************************************/
/***** STYLING RANGE INPUT ***********/
/*************************************/
/*http://brennaobrien.com/blog/2014/05/style-input-type-range-in-every-browser.html */
/*************************************/
.cr-slider {
  -webkit-appearance: none;
  /*removes default webkit styles*/
  /*border: 1px solid white; */
  /*fix for FF unable to apply focus style bug */
  width: 300px;
  /*required for proper track sizing in FF*/
  max-width: 100%;
}

.cr-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  background: rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 3px;
}

.cr-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ddd;
  margin-top: -6px;
}

.cr-slider:focus {
  outline: none;
}

/*
.cr-slider:focus::-webkit-slider-runnable-track {
    background: #ccc;
}
*/
.cr-slider::-moz-range-track {
  width: 100%;
  height: 3px;
  background: rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 3px;
}

.cr-slider::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ddd;
  margin-top: -6px;
}

/*hide the outline behind the border*/
.cr-slider:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px;
}

.cr-slider::-ms-track {
  width: 300px;
  height: 5px;
  background: transparent;
  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  border-color: transparent;
  /*leave room for the larger thumb to overflow with a transparent border */
  border-width: 6px 0;
  color: transparent;
  /*remove default tick marks*/
}

.cr-slider::-ms-fill-lower {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.cr-slider::-ms-fill-upper {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.cr-slider::-ms-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ddd;
}

.cr-slider:focus::-ms-fill-lower {
  background: rgba(0, 0, 0, 0.5);
}

.cr-slider:focus::-ms-fill-upper {
  background: rgba(0, 0, 0, 0.5);
}

/*******************************************/
/***********************************/
/* Rotation Tools */
/***********************************/
.cr-rotate-controls {
  position: absolute;
  bottom: 5px;
  left: 5px;
  z-index: 1;
}

.cr-rotate-controls button {
  border: 0;
  background: none;
}

.cr-rotate-controls i:before {
  display: inline-block;
  font-style: normal;
  font-weight: 900;
  font-size: 1.375rem;
}

.cr-rotate-l i:before {
  content: 'â†º';
}

.cr-rotate-r i:before {
  content: 'â†»';
}

.line_chart_circle {
  stroke-width: 18;
  cursor: pointer;
  stroke-linecap: round;
}

.line_chart_circle:hover {
  stroke-width: 24;
}

.direct_booking_container {
  display: flex;
  margin-top: 40px;
  flex-wrap: wrap;
}
.direct_booking_container .direct_booking_container__users_container {
  flex-basis: 100%;
  margin: auto;
  display: flex;
}
.direct_booking_container .direct_booking_container__users_container .direct_booking_user {
  width: 250px;
}
.direct_booking_container .direct_booking_container__users_container .direct_booking_user:first-child {
  margin-right: 70px;
  margin-left: auto;
}
.direct_booking_container .direct_booking_container__users_container .direct_booking_user:last-child {
  margin-left: 70px;
  margin-right: auto;
}
.direct_booking_container .direct_booking_container__users_container .direct_booking_user h1 {
  text-align: center;
  font-size: 1.5em;
}
.direct_booking_container .direct_booking_container__users_container .direct_booking_user h2 {
  text-align: center;
  font-family: 'Caslon';
  font-size: 1.2em;
  margin-top: 0px;
}
.direct_booking_container .direct_booking_container__users_container .direct_booking_user img {
  margin: auto;
  display: block;
  width: 120px;
}
.direct_booking_container .direct_booking_container__information_container {
  height: 500px;
  width: 600px;
  background: black;
  margin: auto;
  margin-top: 50px;
}
.direct_booking_container .direct_booking_container__information_container .information_container__sides {
  display: flex;
}
.direct_booking_container
  .direct_booking_container__information_container
  .information_container__sides
  .information_container__left_side {
  flex: 1;
}
.direct_booking_container
  .direct_booking_container__information_container
  .information_container__sides
  .information_container__right_side {
  flex: 1;
  text-align: right;
}

.direct_booking_container__title_container {
  margin-top: 50px;
}
.direct_booking_container__title_container h1 {
  text-align: center;
  margin-top: 60px;
  color: #fa4726;
  border-bottom: 1px solid;
  width: 500px;
  margin: auto;
  padding-bottom: 10px;
}

.create_project_button {
  cursor: pointer;
  background-color: white;
}

.create_project_button:hover {
  background-color: #cccccc;
}

.discover_genre_dropdown {
  border: 1px solid #bbbbbb;
  cursor: pointer;
  color: white;
}

.discover_genre_dropdown:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.discover_genre_dropdown_item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

#discover_view {
  width: 100%;
  height: calc(100vh - 200px);
}
#discover_view div {
  color: black;
}
#discover_view div button {
  color: inherit;
}

#discover_view_flash {
  background-color: white;
  width: 100%;
  pointer-events: none;
  height: 100%;
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.landing_video {
  width: 100%;
  height: auto;
  object-fit: cover;
  position: relative;
}

.bottom_gradient {
  float: left;
  width: 100%;
  position: absolute;
  top: 0px;
  margin-top: calc(55% - 50px);
  height: 50px;
  background: linear-gradient(rgba(0, 0, 0, 0), black);
}

.join_button:hover {
  background-color: white;
  color: black;
}

.join_button_about:hover {
  background-color: white;
  color: black;
}

.join_button {
  font-size: 2vw;
  line-height: 5vw;
  text-align: center;
  position: absolute;
  left: 33%;
  top: 0px;
  margin-top: 40%;
  cursor: pointer;
  color: white;
  border: 2px solid white;
  width: 34%;
  height: 5vw;
  letter-spacing: 2px;
  background-color: rgba(255, 255, 255, 0.05);
  font-family: 'Avenir-Heavy', 'Lato';
  font-weight: 800;
}

.join_button_about {
  font-size: 2vw;
  line-height: 5vw;
  text-align: center;
  position: absolute;
  left: 33%;
  top: 0px;
  margin-top: calc(40% - 50px);
  cursor: pointer;
  color: white;
  border: 2px solid white;
  width: 34%;
  height: 5vw;
  letter-spacing: 2px;
  background-color: rgba(255, 255, 255, 0.05);
  font-family: 'Avenir-Heavy', 'Lato';
  font-weight: 800;
}

.nouvelle_garde {
  width: 100%;
  height: 100%;
  color: #333333;
}
.nouvelle_garde .nouvelle_garde_mini_title {
  width: 100%;
  text-align: center;
  font-family: 'Avenir-Roman', 'Lato';
  font-weight: 400;
  font-size: 13px;
  margin-top: 80px;
  height: 20px;
  float: left;
}
.nouvelle_garde .nouvelle_garde_title {
  width: 100%;
  text-align: center;
  font-family: 'Avenir-Roman', 'Lato';
  font-weight: 400;
  font-size: 50px;
  float: left;
}
.nouvelle_garde .nouvelle_garde_subtitle {
  margin-left: 200px;
  margin-top: 20px;
  width: calc(100% - 400px);
  margin-right: 200px;
  text-align: center;
  font-family: 'Avenir-Roman', 'Lato';
  font-weight: 400;
  letter-spacing: 1px;
  color: #444444;
  font-size: 18px;
  float: left;
  margin-bottom: 75px;
}

.faq_container {
  display: flex;
}
.faq_container .faq_big_section {
  margin-bottom: 80px;
}
.faq_container .section {
  padding-left: 30px;
  height: 0px;
  overflow: hidden;
}
@media (max-width: 768px) {
  .faq_container .section {
    padding-left: 0px;
  }
}
.faq_container .section--active {
  height: auto;
}
.faq_container .title {
  font-family: 'Avenir-Black', 'Lato';
  font-weight: 900;
  font-size: 3em;
  letter-spacing: 4px;
  margin-bottom: 30px;
  border-bottom: 1px solid;
}
.faq_container .subtitle {
  font-family: 'Caslon';
  font-size: 1.5em;
  margin-bottom: 30px;
  display: inline-block;
  cursor: pointer;
}
.faq_container .subtitle div {
  display: inline;
}
.faq_container .subtitle img {
  width: 28px;
  /* display: inline; */
  margin-left: 20px;
  margin-top: -3px;
}
.faq_container .subtitle img.active {
  transform: rotate(90deg);
}
.faq_container .question {
  font-size: 1.6em;
  font-weight: bold;
  font-family: 'Avenir-Heavy', 'Lato';
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 8px;
  letter-spacing: 1px;
}
.faq_container .answer {
  margin-bottom: 40px;
  font-size: 1.4em;
}

.filter_slider_label {
  font-family: 'Avenir-Heavy', 'Lato';
  font-weight: 800;
  letter-spacing: 1px;
}

.filter_button {
  color: #aaaaaa;
  border-style: solid;
  margin-left: 20px;
  border-width: 1px;
  border-color: #aaaaaa;
  font-size: 12px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 120px;
  float: left;
}

.filter_button--selected {
  color: white;
  border-color: white;
}

.filter_item_container {
  text-align: center;
}

.filter_item {
  color: white;
  border-style: solid;
  margin-left: 10px;
  border-width: 1px;
  border-color: #777777;
  font-size: 12px;
  text-transform: uppercase;
  height: 32px;
  line-height: 32px;
  font-size: 13px;
  font-family: 'Avenir-Heavy', 'Lato';
  font-weight: 700;
  color: white;
  text-align: center;
  width: 180px;
  float: left;
}

.filter_location {
  color: white;
  margin-left: 0px;
  font-size: 12px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 250px;
  float: left;
}

#filter_bar_network {
  z-index: 10;
  width: calc(100% - 200px);
  height: 100px;
  background-color: black;
  top: 0px;
  border-bottom: 1px solid #333;
  margin-top: 0px;
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.85);
}

.filter_bar_network__item_container {
  width: calc(100%);
  float: left;
  display: flex;
}

.filter_bar_network_item {
  flex: 1;
  height: 80px;
  margin-left: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.filter_bar_network_item_header {
  line-height: 30px;
  font-size: 18px;
  margin-right: 10px;
  color: #ddd;
  font-family: 'Caslon';
  font-weight: 100;
  letter-spacing: 1px;
}

.filter_item--center {
  text-align: center;
  margin-right: 0;
}

#filter_bar_network.filter_bar_network--fixed {
  position: fixed;
  top: 45px;
}

.form-container__input--checkbox {
  display: none;
}

.form-container__input--checkbox_label {
  margin-right: 0px;
  display: flex;
  flex-direction: row;
}

.form-container__input--checkbox + label span {
  display: inline-block;
  width: 19px;
  height: 19px;
  margin: -1px 4px 0 0;
  vertical-align: middle;
  background: url('https://lh5.googleusercontent.com/-VSnKVuXA5k4/UKX5J-71RvI/AAAAAAAAB7Q/hDmvr5xxMjg/s76/check_radio_sheet.png')
    left top no-repeat;
  cursor: pointer;
}

.form-container__input--checkbox:checked + label span {
  background: url('https://lh5.googleusercontent.com/-VSnKVuXA5k4/UKX5J-71RvI/AAAAAAAAB7Q/hDmvr5xxMjg/s76/check_radio_sheet.png') -19px
    top no-repeat;
}

.form-container__input--checkbox__text {
  top: 2px;
  margin-left: 5px;
  font-weight: 200;
  font-family: Avenir;
  letter-spacing: 1px;
}

.hamburger_menu_bound {
  z-index: 9000;
  width: 350px;
  position: fixed;
  top: 45px;
  right: 0px;
  height: 100vh;
}

.hamburger_menu .hamburger_menu_header .hamburger_menu_header_image {
  height: 90px;
}

.hamburger_menu .hamburger_menu_header:hover {
  background-color: #111111;
}

.hamburger_menu .hamburger_menu_element:hover {
  background-color: #111111;
}

.help__container {
  width: 100%;
  height: 100%;
}
.help__container .help__left_partition {
  width: 100%;
  float: left;
  height: 100%;
  position: relative;
}
.help__container .help__left_partition .help__left_partition_content {
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 100px;
  min-width: 500px;
}
.help__container .help__left_partition .help__left_partition_content .help__title {
  font-family: 'Avenir-Medium', 'Lato';
  font-weight: 500;
  font-size: 1.875rem;
  margin-bottom: 40px;
  letter-spacing: 1px;
  width: 100%;
  margin-top: 20px;
  text-align: center;
}
.help__container .help__left_partition .help__left_partition_content .help__content_text {
  font-family: 'Avenir-Roman', 'Lato';
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 40px;
  letter-spacing: 1px;
  width: 100%;
  margin-top: 20px;
  text-align: left;
}
.help__container .help__left_partition .help__left_partition_content .help__content_text_large {
  color: #aaaaaa;
  font-family: 'Avenir-Roman', 'Lato';
  font-weight: 400;
  font-size: 1.375rem;
  margin-bottom: 10px;
  letter-spacing: 1px;
  width: 100%;
  margin-top: 30px;
  text-align: left;
}
.help__container .help__left_partition .help__left_partition_content .help__content_text_large_selectable {
  cursor: pointer;
  color: white;
  font-family: 'Avenir-Roman', 'Lato';
  font-weight: 400;
  font-size: 25px;
  margin-bottom: 50px;
  letter-spacing: 1px;
  width: 100%;
  margin-top: 20px;
  text-align: left;
}
.help__container .help__left_partition .help__left_partition_content .help__content_text_large_selectable:hover {
  color: #fa4726;
}
.help__container .help__left_partition .help__left_partition_content .help__content_text_large_selectable > a {
  cursor: pointer;
  color: white;
  font-family: 'Avenir-Roman', 'Lato';
  font-weight: 400;
  font-size: 25px;
  margin-bottom: 50px;
  letter-spacing: 1px;
  width: 100%;
  margin-top: 20px;
  text-align: left;
}
.help__container .help__left_partition .help__left_partition_content .help__content_text_large_selectable > a:hover {
  color: #fa4726;
  text-decoration: none;
}
.help__container .help__right_partition {
  width: 0%;
  float: left;
  height: 100%;
  background-color: #111111;
}

.hover_opacity {
  transition: opacity 0.1s ease-in-out;
  opacity: 1;
}

.hover_opacity:hover {
  opacity: 0.5;
}

.invoice_container {
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.94);
  position: fixed;
  margin-top: -50px;
  opacity: 1;
  visibility: visible;
  -webkit-transition: visibility 0.15s, opacity, 0.15s -webkit-transform 0.15s;
  transition: visibility 0.15s, opacity 0.15s, transform 0.15s;
  transition-timing-function: ease-in;
  overflow: scroll;
}
.invoice_container .invoice_container__box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*width: 500px;
		max-height: 100vh;*/
  overflow: hidden;
}
.invoice_container .invoice_container__box h1 {
  font-size: 1.6em;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: 20px;
}
.invoice_container .invoice_container__box .scrollable_table {
  max-height: 250px;
  overflow: scroll;
  padding-right: 20px;
  padding-bottom: 10px;
}
.invoice_container .invoice_container__box .invoice_container__table {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.invoice_container .invoice_container__box .invoice_container__table .invoice_close {
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.invoice_container .invoice_container__box .invoice_container__table .invoice_close p {
  color: #8a8a8a;
  border: 1px solid #8a8a8a;
  text-align: right;
  margin-bottom: 0;
  padding: 5px 10px;
  border-radius: 15px;
  margin-top: 5px;
}
.invoice_container .invoice_container__box .invoice_container__table .invoice_close p:hover {
  color: white;
  cursor: pointer;
  border-color: white;
}
.invoice_container .invoice_container__box .invoice_container__table .invoice_table__row {
  flex-basis: 100%;
  display: flex;
  margin-top: 15px;
}
.invoice_container .invoice_container__box .invoice_container__table .invoice_table__row:last-child {
  margin-bottom: 10px;
}
.invoice_container .invoice_container__box .invoice_container__table .invoice_title {
  font-size: 1.4em;
  font-style: italic;
  margin-top: 0px;
  margin-bottom: 5px;
  font-family: 'Caslon';
  flex: 1;
  color: #8a8b8a;
}
.invoice_container .invoice_container__box .invoice_container__table .invoice_title:last-child {
  text-align: right;
}
.invoice_container .invoice_container__box .invoice_container__table .invoice_text {
  font-size: 0.9em;
  color: white;
  letter-spacing: 1px;
  flex: 1;
}
.invoice_container .invoice_container__box .invoice_container__table .invoice_text:last-child {
  text-align: right;
}
.invoice_container .invoice_container__box .invoice_container__table .invoice_text--confirm {
  color: orange;
}

.display_centered_image_container {
  height: 80%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.display_centered_image {
  max-width: 100%;
  max-height: 100%;
}

.display_image_background {
  background-color: rgba(0, 0, 0, 0.92);
  position: fixed;
  width: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  top: 0px;
  text-align: center;
  left: 0px;
}

.profile_new_image {
  flex-grow: 1;
  flex-basis: 200px;
  max-width: 100%;
  border: 1px solid black;
  cursor: pointer;
}
@media (min-width: 400px) {
  .profile_new_image {
    max-width: 50%;
    flex-basis: 50%;
  }
}
@media (min-width: 600px) {
  .profile_new_image {
    max-width: 33.33333%;
    flex-basis: 33.33333%;
  }
}
@media (min-width: 800px) {
  .profile_new_image {
    max-width: 25%;
    flex-basis: 25%;
  }
}
@media (min-width: 1000px) {
  .profile_new_image {
    max-width: 20%;
    flex-basis: 20%;
  }
}
@media (min-width: 1200px) {
  .profile_new_image {
    max-width: 16.66667%;
    flex-basis: 16.66667%;
  }
}
@media (min-width: 1400px) {
  .profile_new_image {
    max-width: 14.28571%;
    flex-basis: 14.28571%;
  }
}
@media (min-width: 1600px) {
  .profile_new_image {
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
}
@media (min-width: 1800px) {
  .profile_new_image {
    max-width: 11.11111%;
    flex-basis: 11.11111%;
  }
}
@media (min-width: 2000px) {
  .profile_new_image {
    max-width: 10%;
    flex-basis: 10%;
  }
}
@media (min-width: 2200px) {
  .profile_new_image {
    max-width: 9.09091%;
    flex-basis: 9.09091%;
  }
}

.profile_new_image:hover {
  opacity: 0.8;
}

.job_underlying_image:hover {
  animation: hoverover 0.6s forwards;
}

@keyframes hoverover {
  0% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.25;
  }
}

.job_container_name {
  margin-left: 20px;
  bottom: 10px;
  width: 300px;
  position: absolute;
  letter-spacing: 2px;
  font-size: 1rem;
  font-family: 'Avenir-Medium', 'Lato';
  font-weight: 500;
}

.post_job_button:hover {
  opacity: 0.8;
}

.post_job_button {
  width: 160px;
  text-align: center;
  height: 40px;
  background-color: white;
  color: #222222;
  text-align: center;
  position: absolute;
  left: calc(50% - 80px);
  font-size: 13px;
  cursor: pointer;
  line-height: 40px;
  margin-top: 20px;
  font-family: 'Avenir-Heavy', 'Lato';
  font-weight: 800;
  letter-spacing: 1px;
}

.job_apply_button {
  height: 40px;
  border: 1px solid white;
  width: 100%;
  width: calc(100% - 30px);
  line-height: 40px;
  text-align: center;
  float: left;
  font-size: 15px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: 'Avenir-Medium';
  letter-spacing: 1px;
}

.job_apply_button:hover {
  border-color: #aaa;
  color: #aaa;
}

.job_apply_button--applied {
  color: #777777;
  border-color: #777777;
}

.job_apply_button--applied:hover {
  border-color: #777;
  color: #777;
}

/* fix starts at line 62 */
.loading_screen {
  z-index: 3000;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0);
}

.loading_screen--deactivated {
  transition: opacity 0.3s ease-out;
  opacity: 0;
  pointer-events: none;
}

.loading_screen--activated {
  transition: opacity 0.3s ease-out;
  opacity: 1;
}

.LoadingNotification {
  height: 0;
  left: 0;
  min-height: 0;
  pointer-events: none;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 300;
  min-height: 100%;
}

.LoadingNotification-anim-orig,
.LoadingNotification-anim {
  height: 160px;
  overflow: visible;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 160px;
  margin-left: -80px;
  margin-top: -80px;
  zoom: 2;
  -moz-transform: scale(1.8);
  display: block;
}

.LoadingNotification-anim-orig {
  left: calc(50% - 80px);
}

.LoadingNotification-anim {
  left: 50%;
}

.LoadingNotification-anim-orig::after,
.LoadingNotification-anim::after {
  display: block;
  position: absolute;
  color: white;
  width: 100%;
  top: 100%;
  text-align: center;
  font-size: 0.5em;
}

.LoadingNotification-anim-orig .circular,
.LoadingNotification-anim .circular {
  height: 160px;
  position: relative;
  width: 160px;
  -webkit-animation: loadingrotate 2s linear infinite;
  animation: loadingrotate 2s linear infinite;
}

.LoadingNotification-anim .path,
.LoadingNotification-anim-orig .path {
  stroke-dasharray: 1, 100;
  stroke-dashoffset: 0;
  stroke-linecap: round;
}

/*
 * THE STUFF THAT MATTERS
*/
/* original and fix animations called here */
.LoadingNotification-anim .path {
  -webkit-animation: dash 1.5s ease-in-out infinite, color 1.5s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite, color 1.5s ease-in-out infinite;
}

@-webkit-keyframes color {
  0%,
  90%,
  96%,
  100% {
    stroke: #fa4726;
  }
  93% {
    stroke: #ffffff;
  }
}

@keyframes color {
  0%,
  90%,
  96%,
  100% {
    stroke: #fa4726;
  }
  93% {
    stroke: #ffffff;
  }
}

/* other animations */
@-webkit-keyframes loadingrotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loadingrotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

.loading_object {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
.loading_object .loading_object_notification {
  pointer-events: none;
  text-align: center;
  width: 100%;
  height: 100%;
}
.loading_object .loading_object_notification-anim-orig,
.loading_object .loading_object_notification-anim {
  height: 100%;
  width: 100%;
  -moz-transform: scale(1.8);
  display: block;
}
.loading_object .loading_object_notification-anim-orig {
  left: calc(50% - 80px);
}
.loading_object .loading_object_notification-anim {
  left: 50%;
}
.loading_object .loading_object_notification-anim-orig::after,
.loading_object .loading_object_notification-anim::after {
  display: block;
  color: white;
  width: 100%;
  text-align: center;
  font-size: 0.5em;
}
.loading_object .loading_object_notification-anim-orig .circular,
.loading_object .loading_object_notification-anim .circular {
  height: 100%;
  width: 100%;
  -webkit-animation: loadingrotate 2s linear infinite;
  animation: loadingrotate 2s linear infinite;
}
.loading_object .loading_object_notification-anim .path,
.loading_object .loading_object_notification-anim-orig .path {
  stroke-dasharray: 1, 100;
  stroke-dashoffset: 0;
  stroke-linecap: round;
}
.loading_object .loading_object_notification-anim .path {
  -webkit-animation: dash 1.5s ease-in-out infinite, color 1.5s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite, color 1.5s ease-in-out infinite;
}

.loading_object--deactivated {
  transition: opacity 0.3s ease-out;
  opacity: 0;
  pointer-events: none;
}

.loading_object--activated {
  transition: opacity 0.3s ease-out;
  opacity: 1;
}

.log_in_wall__title {
  font-size: 1.6em;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: 5px;
}

.message_talent .message_talent__element {
  margin-top: 30px;
  display: flex;
  cursor: pointer;
}
.message_talent .message_talent__element .image_container {
  margin-top: -15px;
  margin-left: 15px;
}
.message_talent .message_talent__element .image_container img {
  height: 40px;
  border-radius: 40px;
  width: 40px;
}
.message_talent .message_talent__element .talent_circle {
  width: 10px;
  height: 10px;
  border-radius: 10px;
}
.message_talent .message_talent__element .element_name {
  margin-top: -5px;
  margin-left: 10px;
  font-family: 'Avenir';
  letter-spacing: 1px;
  color: black;
}
.message_talent .message_talent__element .hoverable {
  border: 1px solid grey;
  background: '';
}
.message_talent .message_talent__element:hover .hoverable {
  border: 1px solid #ff3911;
}

.message_talent .message_talent__filter {
  width: 170px;
  padding-top: 20px;
  font-family: Avenir;
  border-right: 1px solid #777;
}
.message_talent .message_talent__filter ul {
  padding-left: 30px;
  list-style: none;
  padding-bottom: 15px;
}
.message_talent .message_talent__filter ul li {
  margin-bottom: 7px;
  letter-spacing: 1px;
  cursor: pointer;
}

.message_talent .message_talent__list {
  max-width: 340px;
  flex: 1;
  padding-left: 30px;
  padding-top: 20px;
  border-right: 1px solid #777;
  padding-bottom: 30px;
}
.message_talent .message_talent__list .message_talent__list--title {
  font-family: Avenir-Heavy;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.message_talent .message_talent__list--container {
  overflow: scroll;
  padding-left: 10px;
  padding-right: 30px;
  height: calc(100% - 40px);
}

.message_talent .recipient_list {
  font-family: Avenir-Heavy;
  letter-spacing: 1px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-top: 20px;
}
.message_talent .recipient_list .recipient_list--title {
  margin-bottom: 10px;
  padding-right: 20px;
}
.message_talent .recipient_list .recipient_list--container {
  overflow: scroll;
  max-height: calc(100vh - 270px);
  padding-right: 20px;
}
.message_talent .recipient_list .recipient_list--element {
  font-family: Avenir;
  margin-top: 15px;
}

.message_talent .message_broadcast {
  min-width: 350px;
  flex: 1;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
}
.message_talent .message_broadcast .message_broadcast--title {
  font-family: Avenir-Heavy;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-right: 15px;
}
.message_talent .message_broadcast .message_broadcast--helper {
  font-family: Caslon;
  margin-top: 10px;
  font-size: 1.1em;
}
.message_talent .message_broadcast .message_broadcast--error-message {
  color: #fa4726;
  margin-top: 10px;
}
.message_talent .message_broadcast textarea {
  font-family: Avenir-Medium, Lato;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1px;
  border: 1px solid #777;
  background-color: white;
  color: black;
  height: calc(100vh - 300px);
  margin-top: 15px;
  width: 100%;
  padding: 10px;
}
.message_talent .message_broadcast button {
  width: 100%;
  background: black;
  color: white;
  border: none;
  padding-top: 10px;
  padding-bottom: 7px;
  font-size: 0.9em;
  font-family: Avenir;
  letter-spacing: 1px;
  font-weight: bold;
  margin-top: 13px;
}

.job_snapshot {
  width: 100%;
  height: 150px;
  background-color: #040404;
  position: relative;
  float: left;
  border-top: 1px solid #333333;
  border-bottom: 1px solid #333333;
  margin-bottom: 45px;
  display: flex;
}
.job_snapshot:last-child {
  margin-bottom: 40px;
}
.job_snapshot:nth-child(odd) {
  background-color: #111;
}
.job_snapshot .job_snapshot_text_container {
  left: 170px;
  top: 20px;
  font-size: 1rem;
  letter-spacing: 1px;
  font-family: 'Avenir-Heavy', 'Lato';
  font-weight: 800;
  flex: 1;
}
.job_snapshot .job_snapshot_text_container .job_snapshot_text_title {
  margin-left: 25px;
  margin-top: 15px;
  display: flex;
}
.job_snapshot .job_snapshot_text_container .job_snapshot_text_title .job_snapshot_text_title__type {
  margin-left: 25px;
  font-family: 'Avenir';
  font-weight: 100;
  color: #bbb;
}
.job_snapshot .job_snapshot_text_container .job_snapshot_text_title .job_snapshot_text_title__options {
  margin-left: 25px;
  font-family: 'Avenir';
  font-weight: 100;
  color: #bbb;
  flex: 1;
  display: flex;
  padding-right: 30px;
  padding-top: 5px;
  font-size: 0.75em;
  color: white;
}
.job_snapshot
  .job_snapshot_text_container
  .job_snapshot_text_title
  .job_snapshot_text_title__options
  .job_snapshot_text_title__options__option {
  margin-left: 8px;
}
.job_snapshot
  .job_snapshot_text_container
  .job_snapshot_text_title
  .job_snapshot_text_title__options
  .job_snapshot_text_title__options__option:first-child {
  margin-left: auto;
  margin-right: 8px;
}
.job_snapshot
  .job_snapshot_text_container
  .job_snapshot_text_title
  .job_snapshot_text_title__options
  .job_snapshot_text_title__options__option:hover {
  cursor: pointer;
}
.job_snapshot
  .job_snapshot_text_container
  .job_snapshot_text_title
  .job_snapshot_text_title__options
  .job_snapshot_text_title__options__option--green:hover {
  color: #01ff70;
}
.job_snapshot
  .job_snapshot_text_container
  .job_snapshot_text_title
  .job_snapshot_text_title__options
  .job_snapshot_text_title__options__option--red:hover {
  color: #ff4136;
}
.job_snapshot .job_snapshot_text_container .job_snapshot_text_information_content {
  display: flex;
  margin-left: 25px;
  font-family: 'Avenir';
  font-weight: 500;
  font-size: 0.8em;
  margin-top: 10px;
}
.job_snapshot .job_snapshot_text_container .job_snapshot_text_information_content .job_snapshot_text_information_title {
  font-weight: 300;
  color: #bbb;
  width: 90px;
  margin-right: 20px;
}
.job_snapshot .job_snapshot_text_container .job_snapshot_text_information_content .job_snapshot_text_information_info {
  text-transform: uppercase;
}
.job_snapshot
  .job_snapshot_text_container
  .job_snapshot_text_information_content
  .job_snapshot_text_information_button {
  margin-left: auto;
  padding: 6px;
  padding-left: 13px;
  background: #444;
  border-radius: 15px;
  font-size: 0.8em;
  color: white;
  padding-right: 13px;
  margin-top: -7px;
}
.job_snapshot
  .job_snapshot_text_container
  .job_snapshot_text_information_content
  .job_snapshot_text_information_button:hover {
  cursor: pointer;
  color: white;
}
.job_snapshot
  .job_snapshot_text_container
  .job_snapshot_text_information_content
  .job_snapshot_text_information_button:last-child {
  margin-left: 30px;
  margin-right: 30px;
}

.my_jobs_list_view {
  height: 100%;
}
.my_jobs_list_view .my_jobs_list_view__segment {
  width: 100%;
  float: left;
}
.my_jobs_list_view .my_jobs_list_view__subheader {
  height: 50px;
  text-align: center;
  float: left;
  width: 100%;
  position: relative;
}
.my_jobs_list_view .my_jobs_list_view__subheader .my_jobs_list_view__subheader_subtitle {
  font-family: 'Avenir-Heavy', 'Lato';
  font-weight: 800;
  letter-spacing: 2px;
  color: gray;
  font-size: 12px;
  position: absolute;
  position: absolute;
  left: calc(50% - 100px);
  width: 200px;
  top: 10px;
}
.my_jobs_list_view .my_jobs_list_view__subheader .my_jobs_list_view__subheader_title {
  position: absolute;
  left: calc(50% - 100px);
  width: 200px;
  top: 30px;
  text-align: center;
  color: #f47732;
  font-family: 'Avenir-Medium', 'Lato';
  font-weight: 500;
  font-size: 40px;
  letter-spacing: 2px;
}

.my_jobs_container {
  width: calc(100% - 200px);
  height: 100%;
  position: absolute;
  left: 200px;
}
.my_jobs_container .my_jobs_content_container {
  position: absolute;
  top: 30px;
  width: 100%;
  overflow: scroll;
  height: 100%;
  padding-bottom: 60px;
}
.my_jobs_container .my_jobs_header {
  width: 100%;
  top: 50px;
  height: 80px;
  background-color: #111111;
}
.my_jobs_container .my_jobs_header .my_jobs_header_button_holder {
  margin: 0 auto;
  width: 200px;
  position: absolute;
  left: calc(50% - 100px);
  height: 100%;
}
.my_jobs_container .my_jobs_header .my_jobs_header_button_holder .my_jobs_header_button {
  float: left;
  cursor: pointer;
  width: 100px;
  margin-top: 25px;
  height: 30px;
  line-height: 30px;
  border-color: white;
  border-width: 1px;
  border-style: solid;
}
.my_jobs_container .my_jobs_header .my_jobs_header_button_holder .my_jobs_header_button .my_jobs_header_button_text {
  text-align: center;
  line-height: 30px;
  font-family: 'Avenir-Heavy', 'Lato';
  font-weight: 800;
  font-size: 12px;
  width: 100%;
  letter-spacing: 1px;
}
.my_jobs_container .my_jobs_header .my_jobs_header_button_holder .my_jobs_header_button--selected {
  color: black;
  background-color: white;
}

body.network {
  position: fixed;
  left: 0px;
  right: 0px;
}

#network_grid {
  display: table-cell;
  width: 100%;
  float: left;
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#network_grid.network_grid--fixed {
  width: 100%;
  height: 240px;
  margin-top: 100px;
}

/* fade move down */
@-webkit-keyframes fade-move-down-small {
  0% {
    -webkit-transform: translate(0, -10px);
    transform: translate(0, -10px);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
  50% {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
}

@-moz-keyframes fade-move-down-small {
  0% {
    -moz-transform: translate(0, -10px);
    transform: translate(0, -10px);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
  50% {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
  }
  100% {
    -moz-transform: translate(0, 10px);
    transform: translate(0, 10px);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
}

@-o-keyframes fade-move-down-small {
  0% {
    -o-transform: translate(0, -10px);
    transform: translate(0, -10px);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
  50% {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
  }
  100% {
    -o-transform: translate(0, 10px);
    transform: translate(0, 10px);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
}

@keyframes fade-move-down-small {
  0% {
    -webkit-transform: translate(0, -10px);
    -moz-transform: translate(0, -10px);
    -o-transform: translate(0, -10px);
    transform: translate(0, -10px);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
  50% {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 10px);
    -moz-transform: translate(0, 10px);
    -o-transform: translate(0, 10px);
    transform: translate(0, 10px);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
}

/* Header */
.package_user_header {
  width: 100%;
  margin: 0;
  position: fixed;
  z-index: 2;
  background: black;
  height: 50px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}
.package_user_header .package_user_header__options {
  display: flex;
  min-width: 375px;
}
.package_user_header .package_user_header__options2 {
  display: flex;
  font-family: 'AvenirNext-DemiBold', 'Lato', Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #fff;
  font-size: 12px;
  letter-spacing: 2px;
}
.package_user_header .package_user_header__options2 .package_user_header__options2__option {
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  margin-right: 20px;
  font-size: 11px;
  cursor: pointer;
}
.package_user_header .package_user_header__logo {
  color: #fff;
  text-align: center;
  font-family: 'Avenir Next', 'Lato', Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1.2em;
  text-transform: uppercase;
  letter-spacing: 11px;
  display: inline-block;
  line-height: 40px;
  padding-top: 5px;
  z-index: -1;
  position: absolute;
  margin-left: 50px;
}

.back_link {
  display: inline-block;
  padding-right: 20px;
  color: #ccc;
  cursor: pointer;
  text-transform: uppercase;
}

.back_link:hover {
  text-decoration: none;
  color: white;
}

#image_container0 {
  flex: 1;
  height: 100%;
}

#image_0 {
  flex: 1;
  height: 100%;
}

/* CONTAINER */
.package_user_container {
  display: flex;
  flex-flow: row wrap;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  position: relative;
  height: auto;
  background-color: black;
  padding-top: 0px;
  min-height: 100%;
  height: 100%;
  flex-direction: column;
}
.package_user_container .buttons_container {
  margin-top: 30px;
}
.package_user_container .buttons_container .button {
  display: inline-block;
  margin-right: 20px;
  border: 1px solid white;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
}
.package_user_container .container_element {
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  padding-bottom: 0px;
}
.package_user_container .container_element .container_element__content {
  flex: 4;
  height: 100%;
}
.package_user_container .container_element .container_element__content .content_block {
  height: 100%;
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  height: 100%;
  background: black;
  flex-wrap: wrap;
  flex-direction: row;
}
.package_user_container .container_element .container_element__content .content_block .bio_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -50px;
  width: 75%;
  background: transparent;
  max-height: calc(100vh - 215px);
  overflow: scroll;
}
.package_user_container .container_element .container_element__content .content_block .bio_container p {
  font-size: 1.3em;
  text-align: center;
  margin-bottom: 5px;
  font-family: 'Avenir-Light', 'Lato';
  font-weight: 300;
}
.package_user_container
  .container_element
  .container_element__content
  .content_block
  .content_block_user_info
  .view_button {
  z-index: 3;
  left: 50%;
  position: absolute;
  bottom: 115px;
  margin-left: -20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.8em;
  -webkit-animation: fade-move-down-small 1.5s ease-in-out infinite;
  -moz-animation: fade-move-down-small 1.5s ease-in-out infinite;
  -o-animation: fade-move-down-small 1.5s ease-in-out infinite;
  animation: fade-move-down-small 1.5s ease-in-out infinite;
}
.package_user_container
  .container_element
  .container_element__content
  .content_block
  .content_block_user_info
  .view_button
  .view_button_image {
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: middle;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI0Mi40OHB4IiBoZWlnaHQ9IjIyLjI0cHgiIHZpZXdCb3g9IjAgMCA0Mi40OCAyMi4yNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNDIuNDggMjIuMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxkZWZzPjxyZWN0IGlkPSJTVkdJRF8xXyIgeT0iMCIgd2lkdGg9IjQyLjQ4IiBoZWlnaHQ9IjIyLjI0Ii8+PC9kZWZzPjxjbGlwUGF0aCBpZD0iU1ZHSURfMl8iPjx1c2UgeGxpbms6aHJlZj0iI1NWR0lEXzFfIiAgb3ZlcmZsb3c9InZpc2libGUiLz48L2NsaXBQYXRoPjxwb2x5bGluZSBjbGlwLXBhdGg9InVybCgjU1ZHSURfMl8pIiBmaWxsPSJub25lIiBzdHJva2U9IiNGRkZGRkYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHBvaW50cz0iNDEuNDgsMSAyMS4yNCwyMS4yNCAxLDEgIi8+PC9nPjwvc3ZnPg==);
  background-size: 30px auto;
  background-repeat: no-repeat;
  background-position: 50%;
}
.package_user_container
  .container_element
  .container_element__content
  .content_block
  .content_block_user_info
  .view_button
  .view_button_text {
  display: inline-block;
  margin-left: 5px;
}
.package_user_container
  .container_element
  .container_element__content
  .content_block
  .content_block_user_info
  .content_block_user_info__container {
  position: absolute;
  bottom: 35px;
  height: 100%;
  width: 600px;
}
.package_user_container
  .container_element
  .container_element__content
  .content_block
  .content_block_user_info
  .content_block_user_info__container
  .content_block_user_info__content {
  width: 100%;
  height: auto;
  margin-top: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding-left: 50px;
}
.package_user_container
  .container_element
  .container_element__content
  .content_block
  .content_block_user_info
  .content_block_user_info__container
  .content_block_user_info__content
  .job_title {
  width: 80%;
}
.package_user_container
  .container_element
  .container_element__content
  .content_block
  .content_block_user_info
  .content_block_user_info__container
  .content_block_user_info__content
  h1 {
  font-family: 'Lato', Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1.9em;
  font-weight: bold;
  letter-spacing: 2px;
  margin-top: 0px;
  text-transform: uppercase;
}
.package_user_container
  .container_element
  .container_element__content
  .content_block
  .content_block_user_info
  .content_block_user_info__container
  .content_block_user_info__content
  h2 {
  font-size: 1.2em;
  font-style: italic;
  margin-top: 0px;
  margin-bottom: 5px;
  font-family: 'Caslon';
}
.package_user_container
  .container_element
  .container_element__content
  .content_block
  .content_block_user_info
  .content_block_user_info__container
  .content_block_user_info__content
  .info__content {
  vertical-align: middle;
  margin-bottom: 15px;
  margin-top: 15px;
  font-size: 1em;
}
.package_user_container
  .container_element
  .container_element__content
  .content_block
  .content_block_user_info
  .content_block_user_info__container
  .content_block_user_info__content
  .info__content
  span {
  color: #8a8b8a;
  width: 40%;
  max-width: 120px;
  display: inline-block;
}
.package_user_container
  .container_element
  .container_element__content
  .content_block
  .content_block_user_info
  .content_block_user_info__container
  .content_block_user_info__content
  .info__content
  span.measurement {
  color: white;
  vertical-align: middle;
}
.package_user_container
  .container_element
  .container_element__content
  .content_block
  .content_block_user_info
  .content_block_user_info__container
  .content_block_user_info__content
  .info__content--project {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.9em;
}
.package_user_container
  .container_element
  .container_element__content
  .content_block
  .content_block_user_info
  .content_block_user_info__container
  .content_block_user_info__content
  .info__content--project
  .content_element {
  padding: 10px 0;
}
.package_user_container
  .container_element
  .container_element__content
  .content_block
  .content_block_user_info
  .content_block_user_info__container
  .content_block_user_info__content
  .info__content--project
  .content_element
  span:last-child {
  max-width: inherit;
}
.package_user_container
  .container_element
  .container_element__content
  .content_block
  .content_block_user_info
  .content_block_user_info__container
  .content_block_user_info__content
  .info__content--project
  .project_notes {
  width: 80%;
  max-height: 150px;
  overflow: scroll;
}
.package_user_container .container_element .container_element__content .content_block:nth-child(n + 2) {
  margin-top: -50px;
}
.package_user_container .container_element .user_background_image img {
  height: 100%;
  object-fit: cover;
  object-position: 0 0;
}
.package_user_container .container_element .user_background_image--right img {
  float: right;
}
.package_user_container .container_element .user_background_image--left img {
  float: left;
}
.package_user_container .container_element .user_background_image--portfolio {
  flex: 1;
  display: flex;
  height: 100%;
}

.package_user_footer {
  height: 35px;
  left: 0;
  background-color: black;
  position: fixed;
  right: 0;
  bottom: 0;
  border-top: 1px solid white;
  text-transform: uppercase;
  font-size: 0.75em;
}
.package_user_footer .package_user_footer__content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  width: 100%;
}
.package_user_footer .package_user_footer__content .content__user_info {
  padding-left: 20px;
  display: flex;
}
.package_user_footer .package_user_footer__content .content__user_info a {
  text-decoration: none;
}
.package_user_footer .package_user_footer__content .content__user_info .user_info__element {
  margin-right: 20px;
  color: white;
  text-decoration: none;
}
.package_user_footer .package_user_footer__content .content__user_info .user_info__element:hover {
  color: white;
  cursor: pointer;
  text-decoration: none;
}
.package_user_footer .package_user_footer__content .content__user_info .user_info__element--inactive {
  color: #8a8b8a;
}
.package_user_footer .package_user_footer__content .content__user_info .option_element {
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 1em;
  letter-spacing: 1px;
  color: #ccc;
  font-weight: bold;
  border: 1px solid;
  width: 33.33%;
}
.package_user_footer .package_user_footer__content .content__user_info .option_element:hover {
  color: white;
  border: 1px solid white;
  cursor: pointer;
}
.package_user_footer .package_user_footer__content .content__user_info .option_element--active {
  background: white;
  color: black;
  border: none;
}
.package_user_footer .package_user_footer__content .content__user_info .option_element--active:hover {
  color: black;
  border: none;
  cursor: default;
}
.package_user_footer .package_user_footer__content .content__user_info .option_element--half {
  width: 50%;
}
.package_user_footer .package_user_footer__content .content__user_info:first-child {
  padding-left: 50px;
}
.package_user_footer .package_user_footer__content .content__user_info--right {
  margin-left: auto;
  padding-right: 50px;
}
.package_user_footer .package_user_footer__content .content__user_info--middle {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%);
  transform: translate(-50%, -50%);
}
.package_user_footer .package_user_footer__content .content__user_info--options {
  width: 375px;
  height: 34px;
}

.mobile a {
  color: inherit;
  text-decoration: none;
}
.mobile a:hover {
  color: inherit;
  text-decoration: none;
}
.mobile a:active {
  color: inherit;
  text-decoration: none;
}

.mobile .mrow {
  display: flex;
  width: 100%;
}

.mobile .mrow_element {
  flex: 1;
}

.mobile .mcenter {
  text-align: center;
  margin: auto;
}

.mobile .mborder {
  border: 1px solid;
}

.mobile .mborder_bottom {
  border-bottom: 1px solid;
}

.mobile .orange {
  color: #fa4726;
}

.mobile h1 {
  font-family: 'Lato', Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1.4em;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 10px;
  margin-top: 12px;
}

.mobile h2 {
  font-family: 'Avenir';
  margin-top: 0px;
  font-size: 0.9em;
  color: #aaa;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.mobile .measurement {
  margin-top: 5px;
}
.mobile .measurement:first-child {
  margin-top: 0px;
}

.mobile .title_span {
  display: inline-block;
  letter-spacing: 1px;
  width: 30%;
  color: #aaa;
}

.mobile .user .selection_button_container {
  padding-bottom: 25px;
  top: 0px;
  background: black;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 0.8em;
}

.mobile .user .selection_button {
  letter-spacing: 1px;
  text-transform: uppercase;
}

.new_user_bio {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-height: 25px;
  /* fallback */
  max-height: 75px;
  text-overflow: ellipsis;
  margin-bottom: 40px;
}

.package_share_page {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 101;
  opacity: 1;
  visibility: visible;
  -webkit-transition: visibility 0.15s, opacity, 0.15s -webkit-transform 0.15s;
  transition: visibility 0.15s, opacity 0.15s, transform 0.15s;
  transition-timing-function: ease-in;
}

.package_share_content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.package_share_content .error_message {
  margin: auto;
  text-align: center;
  color: #e26e34;
  text-transform: uppercase;
  font-size: 12px;
}
.package_share_content .package_share_content__close {
  float: right;
  margin-top: 20px;
  color: #8a8a8a;
  border: 1px solid #8a8a8a;
  padding: 8px 14px;
  border-radius: 36px;
}
.package_share_content .package_share_content__close:hover {
  color: white;
  border-color: white;
  cursor: pointer;
}
.package_share_content h1 {
  font-size: 1.6em;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: 5px;
}
.package_share_content h2 {
  font-size: 1.2em;
  font-style: italic;
  margin-top: 0px;
  margin-bottom: 5px;
  font-family: 'Caslon';
}
.package_share_content form {
  width: 100%;
  display: flex;
}
.package_share_content form .share_email_input {
  flex: 1;
  margin-top: 10px;
  background: transparent;
  border: 1px solid white;
  margin-bottom: 20px;
}
.package_share_content form .share_email_input:active,
.package_share_content form .share_email_input:focus {
  outline: none;
}
.package_share_content form .share_email_input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 0.8em;
  letter-spacing: 1px;
}
.package_share_content form .share_email_input::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 0.8em;
  letter-spacing: 1px;
}
.package_share_content form .share_email_input:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 0.8em;
  letter-spacing: 1px;
}
.package_share_content form .share_email_input:-moz-placeholder {
  /* Firefox 18- */
  font-size: 0.8em;
  letter-spacing: 1px;
}
.package_share_content form .share_email_submit {
  height: 42px;
  font-size: 0.8em;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background: transparent;
  border: 1px solid white;
  border-left: none;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.package_share_content form .share_email_submit:active,
.package_share_content form .share_email_submit:focus {
  outline: none;
}
.package_share_content form .share_email_submit:hover {
  background: white;
  color: black;
  font-weight: bold;
}

.package_modal_page {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0px;
  left: 0;
  z-index: 1001;
  opacity: 1;
  visibility: visible;
  -webkit-transition: visibility 0.55s, opacity, 0.55s -webkit-transform 0.55s;
  transition: visibility 0.55s, opacity 0.55s, transform 0.55s;
}
.package_modal_page .image_modal {
  margin: auto;
}
.package_modal_page .image_modal .croppie-container {
  margin-bottom: 0px;
}

.package_modal_content {
  width: 600px;
  height: auto;
  margin: auto;
  background-color: white;
  padding: 50px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
}
@media (max-width: 768px) {
  .package_modal_content {
    padding: 30px 20px;
  }
}
@media (max-width: 600px) {
  .package_modal_content {
    width: 100vw;
  }
}
.package_modal_content .error_message {
  margin: auto;
  text-align: center;
  margin-top: 20px;
  color: #e26e34;
  text-transform: uppercase;
  font-size: 12px;
}
.package_modal_content .package_modal_content__close {
  float: right;
  margin-top: 20px;
  color: #8a8a8a;
  border: 1px solid #8a8a8a;
  padding: 8px 14px;
  border-radius: 36px;
}
.package_modal_content .package_modal_content__close:hover {
  color: white;
  border-color: white;
  cursor: pointer;
}
.package_modal_content h1 {
  font-size: 1.6em;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: 14px;
}
.package_modal_content h2 {
  font-size: 1em;
  margin-top: 0px;
  margin-bottom: 5px;
  letter-spacing: 0px;
  font-weight: 300;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  letter-spacing: 1px;
}
.package_modal_content .modal_button_container--wrap {
  flex-wrap: wrap;
}
.package_modal_content .modal_button_container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.package_modal_content .modal_button_container .modal_button {
  font-size: 0.8em;
  margin-top: 20px;
  background: transparent;
  border: 1px solid black;
  color: black;
  text-transform: uppercase;
  letter-spacing: 1px;
  flex: 1;
  padding: 14px 0;
  text-align: center;
}
.package_modal_content .modal_button_container .modal_button:active,
.package_modal_content .modal_button_container .modal_button:focus {
  outline: none;
}
.package_modal_content .modal_button_container .modal_button:hover {
  text-decoration: underline;
  cursor: pointer;
}
.package_modal_content .modal_button_container .modal_button--active {
  background: black;
  color: white;
  font-weight: bold;
  cursor: pointer;
}
.package_modal_content .modal_button_container .modal_button--default:hover {
  text-decoration: none;
  font-weight: bold;
  color: black;
  background: white;
}
.package_modal_content form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.package_modal_content form .modal_input {
  font-size: 0.8em;
  margin-top: 20px;
  background: transparent;
  border: 1px solid black;
  color: black;
  text-transform: uppercase;
  letter-spacing: 1px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 14px 0;
  text-align: center;
}
.package_modal_content form .modal_input:active,
.package_modal_content form .modal_input:focus {
  outline: none;
}
.package_modal_content form .modal_input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 1em;
  letter-spacing: 1px;
}
.package_modal_content form .modal_input::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 1em;
  letter-spacing: 1px;
}
.package_modal_content form .modal_input:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 1em;
  letter-spacing: 1px;
}
.package_modal_content form .modal_input:-moz-placeholder {
  /* Firefox 18- */
  font-size: 1em;
  letter-spacing: 1px;
}
.package_modal_content form .modal_input--space {
  margin-right: 10px;
}
.package_modal_content form .modal_input--space:last-child {
  margin-right: 0px;
}

.package_container {
  display: flex;
  flex-flow: row wrap;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  position: relative;
  height: auto;
  background-color: black;
  padding-top: 100px;
  min-height: 100%;
}
.package_container .package_elements_container {
  display: flex;
  flex-wrap: wrap;
  margin-left: 215px;
  width: 100%;
}
.package_container .package_sidebar_container {
  width: 215px;
  height: 100vh;
  position: fixed;
  border-right: 1px solid grey;
}
.package_container .package_sidebar_container .section {
  font-family: Avenir-Heavy;
  margin-top: 0px;
  letter-spacing: 1px;
}
.package_container .package_sidebar_container .section_menu_option {
  font-size: 0.9em;
  padding-left: 0;
  padding-top: 12px;
  padding-bottom: 10px;
  padding-right: 0;
  margin-bottom: 0;
  border-bottom: 1px solid grey;
  padding-left: 25px;
  position: relative;
}
.package_container .package_sidebar_container .section_menu_option:hover {
  background: #222222;
  cursor: pointer;
}
.package_container .package_sidebar_container .section_menu_option img {
  width: 25px;
  position: absolute;
  right: 10px;
  top: 7px;
}
.package_container .package_sidebar_container .section_menu_option--active {
  color: #fa4726;
  border-color: #fa4726;
}
.package_container .package_sidebar_container .section_title {
  font-size: 18px;
  font-amily: Avenir-Black;
  padding-left: 25px;
  margin-top: 70px;
  letter-spacing: 1px;
}
.package_container .package_filters_container {
  width: 215px;
  height: calc(100vh);
  position: fixed;
  background: black;
  border-right: 1px solid grey;
  overflow: scroll;
}
.package_container .package_filters_container .section {
  margin-top: 20px;
  margin-bottom: 25px;
}
.package_container .package_filters_container .section:last-child {
  padding-bottom: 60px;
}
.package_container .package_filters_container .section_row {
  padding-left: 25px;
  padding-right: 20px;
}
.package_container .package_filters_container .section_title {
  font-family: Avenir-Heavy;
  text-transform: uppercase;
  color: white;
  letter-spacing: 1px;
  margin-bottom: 15px;
  cursor: pointer;
}
.package_container .package_filters_container .section_option {
  padding-left: 40px;
  font-family: Caslon;
  letter-spacing: 0px;
}
.package_container .package_filters_container .section_option:last-child {
  margin-bottom: 15px;
}
.package_container .package_filters_container .section_slider_option {
  height: 40px;
  margin-bottom: 20px;
}
.package_container:first-child {
  padding-top: 0px;
}
.package_container .package_element {
  flex-grow: 1;
  flex-basis: 230px;
  max-width: 100%;
  background: black;
  padding-bottom: 15px;
}
@media (min-width: 460px) {
  .package_container .package_element {
    max-width: 50%;
    flex-basis: 50%;
  }
}
@media (min-width: 690px) {
  .package_container .package_element {
    max-width: 33.33333%;
    flex-basis: 33.33333%;
  }
}
@media (min-width: 920px) {
  .package_container .package_element {
    max-width: 25%;
    flex-basis: 25%;
  }
}
@media (min-width: 1150px) {
  .package_container .package_element {
    max-width: 20%;
    flex-basis: 20%;
  }
}
@media (min-width: 1380px) {
  .package_container .package_element {
    max-width: 16.66667%;
    flex-basis: 16.66667%;
  }
}
@media (min-width: 1610px) {
  .package_container .package_element {
    max-width: 14.28571%;
    flex-basis: 14.28571%;
  }
}
@media (min-width: 1840px) {
  .package_container .package_element {
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
}
@media (min-width: 2070px) {
  .package_container .package_element {
    max-width: 11.11111%;
    flex-basis: 11.11111%;
  }
}
@media (min-width: 2300px) {
  .package_container .package_element {
    max-width: 10%;
    flex-basis: 10%;
  }
}
.package_container .package_element .package_model_picture {
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
  padding-top: 124.9%;
}
.package_container .package_element .package_element_info {
  display: inline-block;
  width: 100%;
}
.package_container .package_element .package_element_info .element_info__options {
  width: 95%;
  margin: auto;
  margin-top: 7px;
  border-radius: 5px;
  overflow: hidden;
  background: #373738;
  padding-top: 8px;
  padding-bottom: 8px;
}
.package_container .package_element .package_element_info .element_info__options .option {
  width: 33.33%;
  display: inline-block;
  text-align: center;
  font-family: 'Lato', Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 0.6em;
  letter-spacing: 1px;
  padding-top: 2px;
  padding-bottom: 2px;
  color: #ddd;
  background: #373738;
  border: none;
  cursor: pointer;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  border-left: 1px solid #777;
}
.package_container .package_element .package_element_info .element_info__options .option:first-child {
  border-left: 0px solid #777;
}
.package_container .package_element .package_element_info .element_info__options .option:hover {
  color: white;
  font-weight: bold;
}
.package_container .package_element .package_element_info .element_info__options .option--active {
  color: white;
  border-color: white;
}
.package_container .package_element .package_element_info .element_info__options .option--selected {
  color: #fa4726;
  font-weight: bold;
  font-family: Avenir-Black;
}
.package_container .package_element .package_element_info .element_info__options .option--selected:hover {
  color: #fa4726;
  font-weight: bold;
  cursor: default;
  font-family: Avenir-Black;
}
.package_container .package_element .package_element_info .element_info__options .option--half {
  flex: 1;
  font-size: 0.6em;
}
.package_container .package_element .package_element_info .element_info__text {
  padding-left: 20px;
  padding-right: 20px;
}
.package_container .package_element .package_element_info .element_info__text h1 {
  font-family: 'Lato', Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 10px;
}
.package_container .package_element .package_element_info .element_info__text h1.element_info__text--selected {
  color: #fa4726;
}
.package_container .package_element .package_element_info .element_info__text h2 {
  font-size: 0.75em;
  font-style: italic;
  margin-top: 0px;
  margin-bottom: 5px;
  font-family: 'Caslon';
}
.package_container .package_element .package_element_info .element_info__text .info__content {
  vertical-align: middle;
  margin-bottom: 15px;
  margin-top: 15px;
  font-size: 12px;
}
.package_container .package_element .package_element_info .element_info__text .info__content span {
  color: #8a8b8a;
  width: 40%;
  max-width: 80px;
  display: inline-block;
}
.package_container .package_element .package_element_info .element_info__text .info__content span.measurement {
  color: white;
}
.package_container .package_element--pass {
  opacity: 0.3;
}

.package_footer {
  height: 35px;
  left: 0;
  background-color: black;
  position: fixed;
  right: 0;
  bottom: 0;
  border-top: 1px solid white;
  text-align: center;
}
.package_footer .footer_content {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.75em;
  width: 100%;
  color: white;
}
.package_footer .footer_content span {
  margin-right: 5px;
}
.package_footer .footer_content span.footer_content--separator {
  margin-right: 35px;
  font-weight: bold;
}
.package_footer .footer_content span.footer_content--selected {
  color: #fa4726;
}
.package_footer .footer_content span.footer_content--passed {
  color: #8a8b8a;
}

.payment_settings__left_partition {
  width: 50%;
  float: left;
  height: 100%;
}

.payment_settings__right_partition {
  width: 50%;
  float: left;
  height: 100%;
  background-color: #111111;
}

.payment_settings_container {
  width: calc(100% - 100px);
  margin-left: 50px;
  margin-top: 50px;
  margin-right: 50px;
}
.payment_settings_container .payment_settings_title {
  font-family: 'Avenir-Black', 'Lato';
  font-weight: 800;
  font-size: 1.875rem;
  letter-spacing: 1px;
  width: 100%;
  float: left;
  height: 100px;
}
.payment_settings_container .payment_settings_section {
  margin-bottom: 50px;
  width: 100%;
  float: left;
}
.payment_settings_container .payment_settings_section .payment_settings_subtitle {
  font-family: 'Avenir-Medium', 'Lato';
  font-weight: 500;
  font-size: 1.375rem;
  letter-spacing: 1px;
  width: 100%;
  text-align: center;
  height: 60px;
  float: left;
  line-height: 60px;
}
.payment_settings_container .payment_settings_section .payment_settings_subtitle_copy--has_info {
  font-family: 'Avenir-Roman', 'Lato';
  font-weight: 400;
  font-size: 15px;
  margin-left: 10%;
  width: 80%;
  color: #bbbbbb;
  text-align: left;
  float: left;
  letter-spacing: 1px;
  line-height: 60px;
  height: 60px;
}
.payment_settings_container .payment_settings_section .payment_settings_subtitle_copy--no_info {
  font-family: 'Avenir-Roman', 'Lato';
  font-weight: 400;
  font-size: 15px;
  margin-left: 10%;
  width: 80%;
  float: left;
  color: #bbbbbb;
  text-align: center;
  letter-spacing: 1px;
  height: 60px;
}
.payment_settings_container .payment_settings_section .payment_settings_button_container {
  float: left;
  width: 100%;
  height: 45px;
}
.payment_settings_container .payment_settings_section .payment_settings_button {
  margin-top: 20px;
  width: 70%;
  font-family: 'Avenir-Medium', 'Lato';
  font-weight: 500;
  font-size: 15px;
  margin-left: 15%;
  background-color: #fffffe;
  color: black;
  border: none;
  letter-spacing: 1px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  height: 40px;
  line-height: 40px;
  float: left;
}

@media screen and (max-width: 1200px) and (min-width: 800px) {
  .grid-image {
    width: 25%;
  }
}

@media screen and (min-width: 1200px) {
  .grid-image {
    width: 20%;
  }
}

@media screen and (max-width: 1000px) {
  .grid-image {
    width: 33.3%;
  }
}

.grid-image :hover {
  opacity: 0.8;
  cursor: pointer;
}

.imageList {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
}
.imageList .chosen {
  opacity: 0.5;
}
.imageList li {
  margin-bottom: 10px;
  margin-right: 20px;
  position: relative;
}
.imageList li .thumbnail_wrapper {
  position: relative;
  height: 250px;
  width: 200px;
  overflow: hidden;
}
.imageList li.draggable:hover {
  border: 1px solid;
  cursor: move;
}
.imageList li.draggable:hover .thumbnail_wrapper {
  height: 248px;
  width: 198px;
}
.imageList li.draggable:hover span {
  visibility: visible;
  z-index: 9;
}
.imageList li span {
  position: absolute;
  right: -10px;
  top: -10px;
  background: #444;
  padding: 2px 8px;
  border: 1px solid white;
  border-radius: 14px;
  cursor: pointer;
  z-index: 101;
  visibility: hidden;
}
.imageList li span.edit {
  left: -10px;
  right: auto;
}
.imageList li span.finish {
  visibility: visible;
}
.imageList li span.back {
  visibility: visible;
  left: -10px;
  right: auto;
}
.imageList li.editing {
  opacity: 0.2;
}
.imageList .imageList__new_element {
  height: 300px;
  display: inline-block;
  width: 240px;
  border: 1px dashed;
  background: transparent;
  vertical-align: middle;
  position: relative;
  opacity: 0.6;
}
.imageList .imageList__new_element label:hover {
  opacity: 1;
  cursor: pointer;
}
.imageList input:focus {
  outline: none;
}

.menu_option {
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 0.85em;
  letter-spacing: 1px;
  font-weight: bold;
  color: #888;
}
.menu_option:hover {
  color: white;
  cursor: pointer;
  border: 1px solid white;
}

.menu_option--selected {
  background: white;
  color: black;
  cursor: pointer;
  border: 1px solid white;
}
.menu_option--selected:hover {
  color: black;
  cursor: default;
  border: 1px solid white;
}

.menu_option--plus {
  margin-right: 20px;
  margin-left: 30px;
  margin-top: -5px;
  border: 1px solid black;
  border-radius: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 14px;
  padding-right: 14px;
  color: white;
}
.menu_option--plus:hover {
  background: black;
  color: white;
  cursor: pointer;
  border: 1px solid white;
}
.menu_option--plus:hover .fa {
  color: white;
}

.image_cropper {
  position: fixed;
  top: 0;
  z-index: 100;
  background: black;
  opacity: 0.9;
  width: 100%;
  height: 100vh;
}
.image_cropper .image_cropper__content {
  top: 50%;
  position: absolute;
  left: 50%;
}

.project_info_page {
  width: 80%;
  height: calc(100vh - 40px);
  background: white;
  position: fixed;
  overflow: scroll;
  z-index: 2000;
  left: 100%;
  -webkit-transition: left 0.2s, -webkit-transform 0.2s;
  transition: left 0.2s, transform 0.2s;
  border-left: 1px solid #777;
}
.project_info_page .project_info_header {
  height: 40px;
  border-bottom: 1px solid #777;
  position: absolute;
  width: 100%;
  background: white;
}
.project_info_page .project_info_header .project_info_title {
  display: inline-block;
  width: auto;
  margin-top: 12px;
  margin-left: 25px;
  font-size: 0.85em;
  letter-spacing: 2px;
  font-weight: 500;
}
.project_info_page .project_info_header .project_info_close {
  float: right;
  cursor: pointer;
}
.project_info_page .project_info_header .project_info_close .project_info_close__text {
  float: right;
  margin-top: 12px;
  margin-right: 10px;
  font-size: 0.8em;
  letter-spacing: 1px;
  cursor: pointer;
}
.project_info_page .project_info_header .project_info_close:hover .button__go-back--project_info {
  color: black;
}
.project_info_page .button__go-back--project_info {
  margin-top: -1px;
  margin-right: 20px;
  font-size: 25px;
}
.project_info_page .project_info_container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
}
.project_info_page .project_info_container .project_info_image {
  background-image: url('https://lh3.googleusercontent.com/l0W6yUfynyzoHi2XK9wdmwn_OGKBZWg0ftVHdUw2eudhO53EpF638N7osdImnHWLSy1wjrH2CiJElLRvrl0gRSVd4yZvJPyN07Q=s600');
  width: 45%;
  height: 100%;
  z-index: -27;
  background-size: cover;
  border-top: 40px solid transparent;
}
.project_info_page .project_info_container .project_info_content {
  border-top: 40px solid transparent;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}
.project_info_page .project_info_container .project_info_content .project_info_content__element {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #414241;
}
.project_info_page .project_info_container .project_info_content .project_info_content__element .element__container {
  font-size: 0.9em;
  letter-spacing: 1px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-transform: uppercase;
}
.project_info_page
  .project_info_container
  .project_info_content
  .project_info_content__element
  .element__container
  .button {
  text-align: center;
  margin: 0 10px;
  padding: 5px 0;
  border: 1px solid #777;
}
.project_info_page
  .project_info_container
  .project_info_content
  .project_info_content__element
  .element__container
  .button:hover {
  background: black;
  color: white;
  cursor: pointer;
}
.project_info_page
  .project_info_container
  .project_info_content
  .project_info_content__element
  .element__container
  span {
  flex: 4;
}
.project_info_page .project_info_container .project_info_content .project_info_content__element .element__container ul {
  flex: 4;
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  margin-bottom: 0;
}
.project_info_page
  .project_info_container
  .project_info_content
  .project_info_content__element
  .element__container
  .element_title {
  color: #8a8b8a;
  display: inline-block;
  flex: 1;
  margin-left: 50px;
  min-width: 100px;
}
.project_info_page .project_info_container .project_info_content .project_info_content__element:last-child {
  border-bottom: none;
}

.toggle_button_container {
  width: 300px;
  float: left;
  position: relative;
  height: 20px;
  left: calc(50% - 150px);
  cursor: pointer;
}
.toggle_button_container .toggle_button_backdrop:hover {
  background-color: #333333;
}
.toggle_button_container .toggle_button_backdrop {
  background-color: #222222;
  border-radius: 13px;
  height: 26px;
  width: 100%;
  position: absolute;
  top: 0px;
}
.toggle_button_container .toggle_button_center.active {
  background-color: #fa4726;
  left: 4px;
}
.toggle_button_container .toggle_button_center.inactive {
  background-color: #777;
  right: 4px;
}
.toggle_button_container .toggle_button_center {
  height: 18px;
  pointer-events: none;
  border-radius: 8px;
  width: 45%;
  position: absolute;
  top: 4px;
}

#sign_up_button {
  cursor: pointer;
}

.google_maps_input--location {
  font-size: 0.9em;
  width: 70%;
  height: 32px;
  background-color: black;
  text-align: center;
  border: 1px solid #8a8a8a;
  height: 45px;
  text-transform: uppercase;
  margin-bottom: 5px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.new_user_bio {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-height: 25px;
  /* fallback */
  max-height: 75px;
  text-overflow: ellipsis;
  margin-bottom: 40px;
}

.package_share_page {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 101;
  opacity: 1;
  visibility: visible;
  -webkit-transition: visibility 0.15s, opacity, 0.15s -webkit-transform 0.15s;
  transition: visibility 0.15s, opacity 0.15s, transform 0.15s;
  transition-timing-function: ease-in;
}

.sign-up-page__main-container {
  width: 100%;
  height: auto;
  min-height: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.sign-up-page__main-container .main-container__form-container {
  width: 100%;
  margin: auto;
}
.sign-up-page__main-container .main-container__form-container .form-container__title {
  letter-spacing: 0.2em;
  font-size: 1.5em;
  margin-bottom: 40px;
  text-align: center;
  padding-right: 30px;
  padding-left: 30px;
}
.sign-up-page__main-container .main-container__form-container .form-container__title .form-container__title--step {
  color: #e26e34;
}
.sign-up-page__main-container .main-container__form-container .form-container__title--non-main {
  font-size: 1.8em;
  letter-spacing: 0.1em;
  margin-top: 0px;
  margin-bottom: 10px;
}
.sign-up-page__main-container .main-container__form-container .form-container__subtitle {
  letter-spacing: 1px;
  text-align: center;
  text-transform: none;
  width: 65%;
  margin: auto;
  font-weight: 400;
  font-family: 'Avenir-Medium', 'Lato';
  font-size: 1.3em;
  display: block;
  margin-top: 40px;
  line-height: 1.4em;
}
.sign-up-page__main-container .main-container__form-container .form-container__subtitle strong {
  color: #e26e34;
}
.sign-up-page__main-container .main-container__form-container .form-container__subtitle--contact-us {
  font-size: 1em;
}
.sign-up-page__main-container .main-container__form-container .form-container__subtitle--large {
  width: 90%;
}
.sign-up-page__main-container .main-container__form-container .form-container__form {
  text-align: center;
}
.sign-up-page__main-container .main-container__form-container .form-container__form--step {
  width: 70%;
  margin: auto;
  margin-top: 60px;
}
.sign-up-page__main-container .main-container__form-container .form-container__form--image {
  margin-top: 40px;
}
.sign-up-page__main-container .main-container__form-container .form-container__error_message_container {
  width: 70%;
  margin: auto;
  text-align: center;
  margin-top: 20px;
  color: #e26e34;
  text-transform: uppercase;
  font-size: 12px;
}
.sign-up-page__main-container .main-container__form-container .form-container__forgot_password {
  margin: auto;
  text-align: center;
  margin-top: 20px;
  font-size: 12px;
  color: white;
}
.sign-up-page__main-container .main-container__form-container .form-container__forgot_password:hover {
  color: #7fdbff;
  cursor: pointer;
}
.sign-up-page__main-container .main-container__form-container .form-container__group {
  margin-top: 15px;
}
.sign-up-page__main-container .main-container__form-container .form-container__input {
  width: 70%;
  height: 32px;
  background-color: black;
  text-align: center;
  border: 1px solid #8a8a8a;
  height: 45px;
  margin-bottom: 5px;
  letter-spacing: 1px;
}
.sign-up-page__main-container .main-container__form-container .form-container__input:active {
  outline: none;
  border: 2px solid white;
}
.sign-up-page__main-container .main-container__form-container .form-container__input:focus {
  outline: none;
  border: 2px solid white;
}
.sign-up-page__main-container .main-container__form-container .form-container__input--active {
  border: 2px solid white;
}
.sign-up-page__main-container .main-container__form-container .form-container__input--checkbox {
  display: none;
}
.sign-up-page__main-container .main-container__form-container .form-container__input--checkbox + label span {
  display: inline-block;
  width: 19px;
  height: 19px;
  margin: -1px 4px 0 0;
  vertical-align: middle;
  background: url('https://lh5.googleusercontent.com/-VSnKVuXA5k4/UKX5J-71RvI/AAAAAAAAB7Q/hDmvr5xxMjg/s76/check_radio_sheet.png')
    left top no-repeat;
  cursor: pointer;
}
.sign-up-page__main-container .main-container__form-container .form-container__input--checkbox:checked + label span {
  background: url('https://lh5.googleusercontent.com/-VSnKVuXA5k4/UKX5J-71RvI/AAAAAAAAB7Q/hDmvr5xxMjg/s76/check_radio_sheet.png') -19px
    top no-repeat;
}
.sign-up-page__main-container .main-container__form-container .form-container__input--label {
  color: #8a8b8a;
  font-weight: 500;
  font-size: 11px;
  width: 70%;
}
.sign-up-page__main-container .main-container__form-container .form-container__input--label a {
  margin-left: 2px;
  border-bottom: 1px solid;
  color: #8a8a8a;
  padding-bottom: 2px;
}
.sign-up-page__main-container .main-container__form-container .form-container__input--label a:hover {
  cursor: pointer;
  color: white;
}
.sign-up-page__main-container .main-container__form-container .form-container__input--label a,
.sign-up-page__main-container .main-container__form-container .form-container__input--label a:hover,
.sign-up-page__main-container .main-container__form-container .form-container__input--label a:active,
.sign-up-page__main-container .main-container__form-container .form-container__input--label a:visited {
  text-decoration: none;
}
.sign-up-page__main-container
  .main-container__form-container
  .sign-up-page
  .sign-up-page__main-container
  .main-container__form-container
  .form-container__group:focus
  .form-container__input--label {
  color: white;
}
.sign-up-page__main-container .main-container__form-container .form-container__input--submit {
  margin-top: 20px;
  width: 70%;
  background-color: #fffffe;
  color: black;
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  letter-spacing: 1px;
  font-weight: bold;
}
.sign-up-page__main-container .main-container__form-container .form-container__input--step {
  margin-top: 0px;
}
.sign-up-page__main-container .main-container__form-container .form-container__submit--location {
  width: 100%;
}
.sign-up-page__main-container .main-container__form-container .form-container__file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.sign-up-page__main-container .main-container__form-container .form-container__file + label {
  margin-top: 20px;
  width: 70%;
  background-color: #fffffe;
  color: black;
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  letter-spacing: 1px;
  font-weight: bold;
}
.sign-up-page__main-container .main-container__form-container .form-container__file + label:hover {
  cursor: pointer;
}
.sign-up-page__main-container .main-container__form-container .form-container__file:focus + label {
  outline: none;
  border: 1px solid #4d90fe;
  -webkit-box-shadow: 0px 0px 5px #4d90fe;
  box-shadow: 0px 0px 5px #4d90fe;
}
.sign-up-page__main-container .sign-up-dropdown__container {
  width: 70%;
  padding-left: 1px;
  margin: auto;
}
.sign-up-page__main-container .sign-up-dropdown__container .dropdown-container__elements-container {
  width: 85%;
}
.sign-up-page__main-container
  .sign-up-dropdown__container
  .dropdown-container__elements-container
  .dropdown-container___element {
  border-bottom: 1px solid #8a8a8a;
  border-left: 1px solid #8a8a8a;
  border-right: 1px solid #8a8a8a;
  padding: 10px 0;
  text-align: center;
  color: #8a8a8a;
  width: 99.8%;
}
.sign-up-page__main-container
  .sign-up-dropdown__container
  .dropdown-container__elements-container
  .dropdown-container___element:last-child {
  margin-bottom: 10px;
}
.sign-up-page__main-container
  .sign-up-dropdown__container
  .dropdown-container__elements-container
  .dropdown-container___element:hover {
  color: white;
  border: 1px solid;
  cursor: pointer;
}
.sign-up-page__main-container .sign-up-dropdown__container--no-options {
  height: inherit;
  overflow: hidden;
  clear: both;
}
.sign-up-page__main-container .sign-up-dropdown__options {
  border: 1px solid #8a8a8a;
  text-align: center;
  color: #8a8a8a;
  width: 100%;
  margin: auto;
  overflow: auto;
}
.sign-up-page__main-container .sign-up-dropdown__options:hover,
.sign-up-page__main-container .sign-up-dropdown__options:focus,
.sign-up-page__main-container .sign-up-dropdown__options--active {
  cursor: pointer;
  border: 1px solid white;
  outline-width: 0;
}
.sign-up-page__main-container .sign-up-dropdown__options:hover .sign-up-dropdown__message,
.sign-up-page__main-container .sign-up-dropdown__options:focus .sign-up-dropdown__message,
.sign-up-page__main-container .sign-up-dropdown__options--active .sign-up-dropdown__message {
  border-right: 1px solid white;
  color: white;
}
.sign-up-page__main-container .sign-up-dropdown__options:hover .sign-up-dropdown__selector,
.sign-up-page__main-container .sign-up-dropdown__options:focus .sign-up-dropdown__selector,
.sign-up-page__main-container .sign-up-dropdown__options--active .sign-up-dropdown__selector {
  color: white;
}
.sign-up-page__main-container .sign-up-dropdown__message {
  width: 85%;
  float: left;
  padding: 15px 0;
  border-right: 1px solid #8a8a8a;
}
.sign-up-page__main-container .sign-up-dropdown__selector {
  width: 15%;
  float: right;
  padding: 10px 0;
  color: #8a8a8a;
}
.sign-up-page__main-container .sign-up-dropdown__selector:hover {
  color: white;
  cursor: pointer;
}
.sign-up-page__main-container .sign-up-download-button {
  width: 55%;
  margin: auto;
  text-align: center;
  margin-top: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: black;
  background: white;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 1.1em;
  text-decoration: none;
  display: block;
}

@-webkit-keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.mk-spinner-ring__inside {
  -webkit-transition: opacity, 0.15s -webkit-transform 0.15s;
  transition: opacity 0.15s, transform 0.15s;
  transition-timing-function: ease-in;
}

.mk-spinner-ring {
  content: '';
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  margin-top: -25px;
  left: 50%;
  margin-left: -25px;
  z-index: 1;
  border-radius: 50%;
  border: 2px solid #fa4726;
  border-left-color: transparent;
  background: transparent;
  -webkit-animation: rotating 1s linear infinite;
  -moz-animation: rotating 1s linear infinite;
  -ms-animation: rotating 1s linear infinite;
  -o-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
  -webkit-transition: opacity, 0.15s -webkit-transform 0.15s;
  transition: opacity 0.15s, transform 0.15s;
  transition-timing-function: ease-in;
}

.mk-spinner-ring--image {
  margin-top: -80px;
  margin-left: -40px;
}

.croppie-container {
  margin: auto;
  padding: 0;
}

.cr-boundary {
  border: 1px solid white;
  padding-right: 1px;
}

.cr-slider {
  border: 1px solid #808080;
}

.croppie-container .cr-viewport {
  border: 2px dashed white !important;
}

.croppie-container {
  margin-bottom: 50px;
  padding: 0px !important;
}

.image-slider {
  display: inline-block;
  width: 70%;
  vertical-align: middle;
}
.create_job_OLD .image-slider {
  width: 100%;
}

.image-slider .image-slider__ul {
  height: 300px;
}
.image-slider .image-slider__ul .image-slider__li {
  /* max-width: 100%; */
  width: 100%;
  float: left;
  list-style: none;
}
.image-slider .image-slider__ul .image-slider__li--active {
  visibility: visible;
}
.image-slider .image-slider__ul .image-slider__li--inactive {
  visibility: hidden;
}

.image-slider-button-container {
  margin: auto;
  margin-top: 15px;
}
.image-slider-button-container .image-slider-button {
  width: 47%;
  float: left;
  margin-top: 10px;
  background-color: black;
  color: white;
  border: none;
  font-size: 0.8em;
  padding-top: 10px;
  padding-bottom: 10px;
  letter-spacing: 1px;
  font-weight: bold;
  cursor: pointer;
}
.image-slider-button-container .image-slider-button:disabled {
  opacity: 0.6;
}
.image-slider-button-container .image-slider-button--right {
  float: right;
}
.image-slider-button-container .image-slider-button--full {
  width: 100%;
}

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active,
.bx-wrapper .bx-pager.bx-default-pager a:focus {
  background-color: white;
}

.bx-wrapper {
  box-shadow: none;
  border: none;
  background: none;
}

.bx-viewport {
  height: 295px !important;
}

.loading-container {
  width: 200px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}
.loading-container .loading_message {
  margin-top: 15px;
}
.loading-container .mk-spinner-ring {
  content: '';
  display: block;
  position: static;
  width: 80px;
  height: 80px;
  top: 0%;
  margin-top: 0;
  left: 0%;
  margin-left: 0px;
  border-radius: 50%;
  border: 3px solid #00bcd4;
  border-left-color: transparent;
  background: transparent;
  -webkit-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
  margin: auto;
}

.pac-container {
  border-top: 0px solid #8a8a8a;
  z-index: 20000;
}

.pac-container:after {
  background-image: image-url('icons/powered-by-google-on-non-white.png') !important;
  background-color: black;
}

.pac-icon-marker {
  display: none;
}

.pac-item {
  background-color: black;
  border: 1px solid #8a8a8a;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.pac-item .pac-item-query {
  color: white;
  font-size: 1em !important;
  padding-left: 5px;
}

.pac-item:hover .pac-item-query {
  color: black;
}

.pac-item-selected {
  border: 1px solid white;
}

/* Gotham Pro */
@font-face {
  font-family: 'Gotham Pro';
  src: url('fonts/GothamPro-BoldItalic.eot');
  src: local('Gotham Pro Bold Italic'), local('GothamPro-BoldItalic'),
    url('fonts/GothamPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('fonts/GothamPro-BoldItalic.woff') format('woff'), url('fonts/GothamPro-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('fonts/GothamPro-Italic.eot');
  src: local('Gotham Pro Italic'), local('GothamPro-Italic'),
    url('fonts/GothamPro-Italic.eot?#iefix') format('embedded-opentype'),
    url('fonts/GothamPro-Italic.woff') format('woff'), url('fonts/GothamPro-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('fonts/GothamPro-Medium.eot');
  src: local('Gotham Pro Medium'), local('GothamPro-Medium'),
    url('fonts/GothamPro-Medium.eot?#iefix') format('embedded-opentype'),
    url('fonts/GothamPro-Medium.woff') format('woff'), url('fonts/GothamPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('fonts/GothamPro-BlackItalic.eot');
  src: local('Gotham Pro Black Italic'), local('GothamPro-BlackItalic'),
    url('fonts/GothamPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('fonts/GothamPro-BlackItalic.woff') format('woff'), url('fonts/GothamPro-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('fonts/GothamPro-Bold.eot');
  src: local('Gotham Pro Bold'), local('GothamPro-Bold'),
    url('fonts/GothamPro-Bold.eot?#iefix') format('embedded-opentype'), url('fonts/GothamPro-Bold.woff') format('woff'),
    url('fonts/GothamPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro Narrow';
  src: url('fonts/GothamProNarrow-Bold.eot');
  src: local('Gotham Pro Narrow Bold'), local('GothamProNarrow-Bold'),
    url('fonts/GothamProNarrow-Bold.eot?#iefix') format('embedded-opentype'),
    url('fonts/GothamProNarrow-Bold.woff') format('woff'), url('fonts/GothamProNarrow-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro Narrow';
  src: url('fonts/GothamProNarrow-Medium.eot');
  src: local('Gotham Pro Narrow Medium'), local('GothamProNarrow-Medium'),
    url('fonts/GothamProNarrow-Medium.eot?#iefix') format('embedded-opentype'),
    url('fonts/GothamProNarrow-Medium.woff') format('woff'), url('fonts/GothamProNarrow-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('fonts/GothamPro-LightItalic.eot');
  src: local('Gotham Pro Light Italic'), local('GothamPro-LightItalic'),
    url('fonts/GothamPro-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('fonts/GothamPro-LightItalic.woff') format('woff'), url('fonts/GothamPro-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('fonts/GothamPro-Light.eot');
  src: local('Gotham Pro Light'), local('GothamPro-Light'),
    url('fonts/GothamPro-Light.eot?#iefix') format('embedded-opentype'),
    url('fonts/GothamPro-Light.woff') format('woff'), url('fonts/GothamPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('fonts/GothamPro-Black.eot');
  src: local('Gotham Pro Black'), local('GothamPro-Black'),
    url('fonts/GothamPro-Black.eot?#iefix') format('embedded-opentype'),
    url('fonts/GothamPro-Black.woff') format('woff'), url('fonts/GothamPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('fonts/GothamPro.eot');
  src: local('Gotham Pro'), local('GothamPro'), url('fonts/GothamPro.eot?#iefix') format('embedded-opentype'),
    url('fonts/GothamPro.woff') format('woff'), url('fonts/GothamPro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('fonts/GothamPro-MediumItalic.eot');
  src: local('Gotham Pro Medium Italic'), local('GothamPro-MediumItalic'),
    url('fonts/GothamPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('fonts/GothamPro-MediumItalic.woff') format('woff'), url('fonts/GothamPro-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

/*
 * Template Name: Delta - Responsive Website Template for mobile app
 * Version: 1.0
 * Author: DarwinApps
 * Website: http://darwinapps.com/
*/
@font-face {
  font-family: HelveticaNeue-Thin;
  src: url('fonts/HelveticaNeueLTStd_Th.otf') format('opentype');
}

@font-face {
  font-family: HelveticaNeue-Medium;
  src: url('fonts/HelveticaNeueLTStd_Md.otf') format('opentype');
}

@font-face {
  font-family: 'Caslon';
  src: url('fonts/Caslon_540_ITALIC.ttf') format('truetype');
}

@font-face {
  font-family: 'StyreneA-Medium';
  src: url('fonts/StyreneA-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'StyreneA-Regular';
  src: url('fonts/StyreneA-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'StyreneB-Regular';
  src: url('fonts/StyreneB-Regular.otf') format('opentype');
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/****************************************************************/
/* BASE */
/****************************************************************/
html {
  height: 100%;
}

html,
body {
  font-size: 16px;
  height: 100%;
  text-rendering: optimizeLegibility;
}
@media (min-width: 769px) and (max-width: 1400px) {
  html,
  body {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  html,
  body {
    font-size: 14px;
  }
}
@media (max-width: 420px) {
  html {
    font-size: 14px;
  }
}

body {
  font-family: 'Lato', Helvetica Neue, Helvetica, Arial, sans-serif;
  color: black;
  width: 100%;
  position: relative;
  min-height: 100%;
  overflow: auto;
}

a {
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

a:active,
a:focus {
  outline: none;
}

.underline {
  border-bottom: 1px solid #ddd;
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

/****************************************************************/
/* LAYOUT HELPERS */
/****************************************************************/
.how-it-works-1 {
  margin-top: 25px;
}

.how-it-works-2 {
  margin-top: 25px;
}

.aside-nav {
  display: none !important;
}

.screen {
  height: 100vh;
  min-height: 600px;
  position: relative;
}

.scroll-down-link {
  display: block;
  width: 44px;
  height: 44px;
  margin-left: -22px;
  position: fixed;
  left: 50%;
  bottom: 40px;
  z-index: 1000;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI0Mi40OHB4IiBoZWlnaHQ9IjIyLjI0cHgiIHZpZXdCb3g9IjAgMCA0Mi40OCAyMi4yNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNDIuNDggMjIuMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxkZWZzPjxyZWN0IGlkPSJTVkdJRF8xXyIgeT0iMCIgd2lkdGg9IjQyLjQ4IiBoZWlnaHQ9IjIyLjI0Ii8+PC9kZWZzPjxjbGlwUGF0aCBpZD0iU1ZHSURfMl8iPjx1c2UgeGxpbms6aHJlZj0iI1NWR0lEXzFfIiAgb3ZlcmZsb3c9InZpc2libGUiLz48L2NsaXBQYXRoPjxwb2x5bGluZSBjbGlwLXBhdGg9InVybCgjU1ZHSURfMl8pIiBmaWxsPSJub25lIiBzdHJva2U9IiNGRkZGRkYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHBvaW50cz0iNDEuNDgsMSAyMS4yNCwyMS4yNCAxLDEgIi8+PC9nPjwvc3ZnPg==);
  background-size: 44px auto;
  background-repeat: no-repeat;
  background-position: 50%;
  -webkit-animation: fade-move-down 2s ease-in-out infinite;
  -moz-animation: fade-move-down 2s ease-in-out infinite;
  -o-animation: fade-move-down 2s ease-in-out infinite;
  animation: fade-move-down 2s ease-in-out infinite;
}

.scroll-down-link:hover,
.scroll-down-link:active,
.scroll-down-link:focus {
  outline: none;
}

/****************************************************************/
/* HEADER */
/****************************************************************/
.header-main {
  padding: 15px 0;
  background: rgba(0, 0, 0, 0);
  /*
    background: -moz-linear-gradient(top,  rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0.65)), color-stop(100%,rgba(0,0,0,0)));
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
    background: -o-linear-gradient(top,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
    background: -ms-linear-gradient(top,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
    background: linear-gradient(to bottom,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    */
}

/* logo */
.logo {
  float: left;
  margin: 0;
  padding: 0;
  opacity: 0;
}

.logo a {
  color: #fff;
  font-family: 'Avenir Next', 'Lato', Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1.375rem;
  text-transform: uppercase;
  letter-spacing: 11px;
  display: block;
  line-height: 40px;
}

.logo a:hover,
.logo a:active,
.logo a:focus {
  color: #fff;
  text-decoration: none;
  outline: none;
}

/* header nav */
.header-main .main-nav .nav .nav-item {
  text-transform: uppercase;
  margin-right: 5px;
  font-family: 'AvenirNext-DemiBold', 'Lato', Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 2px;
}

.header-main .main-nav .nav .nav-item .scrollto {
  opacity: 0;
}

.header-main .main-nav .nav .nav-item.active a {
  color: #fff;
  background: none;
}

.header-main .main-nav .nav .nav-item a {
  color: #fff;
  font-size: 12px;
  padding: 0 10px;
  line-height: 40px;
}

.header-main .main-nav .nav .nav-item a:hover,
.header-main .main-nav .nav .nav-item a:active {
  color: #f47732;
  background-color: transparent;
  outline: none;
}

.header-main .main-nav .nav .nav-item a:focus {
  background-color: transparent;
  outline: none;
}

.header-main .main-nav .nav .nav-item.active a {
  color: #f47732;
}

.header-main .main-nav .nav .nav-item.last {
  margin-right: 0;
}

.header-main .main-nav .nav .nav-item img {
  vertical-align: top;
}

.header-main .btn-app-store {
  display: inline-block;
  width: 109px;
  height: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-indent: -9999em;
  background: image-url('extras/app-store-btn-@2x.png') 50% / auto 40px no-repeat;
  opacity: 0.9;
  margin-left: 15px;
}

.header-main .btn-app-store:hover {
  opacity: 1;
}

.header-main .btn-download-now {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  background: #000;
  border: 1px solid #606064;
  color: #fff;
  margin-left: 10px;
  padding: 0 20px !important;
}

.header-main .btn-download-now:hover {
  color: #fff !important;
  border-color: #bbb;
}

.header-main .btn-download-now .icon-arrow-down {
  display: inline-block;
  width: 14px;
  height: 12px;
  margin-left: 4px;
  vertical-align: baseline;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI0Mi40OHB4IiBoZWlnaHQ9IjIyLjI0cHgiIHZpZXdCb3g9IjAgMCA0Mi40OCAyMi4yNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNDIuNDggMjIuMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxkZWZzPjxyZWN0IGlkPSJTVkdJRF8xXyIgeT0iMCIgd2lkdGg9IjQyLjQ4IiBoZWlnaHQ9IjIyLjI0Ii8+PC9kZWZzPjxjbGlwUGF0aCBpZD0iU1ZHSURfMl8iPjx1c2UgeGxpbms6aHJlZj0iI1NWR0lEXzFfIiAgb3ZlcmZsb3c9InZpc2libGUiLz48L2NsaXBQYXRoPjxwb2x5bGluZSBjbGlwLXBhdGg9InVybCgjU1ZHSURfMl8pIiBmaWxsPSJub25lIiBzdHJva2U9IiNGRkZGRkYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHBvaW50cz0iNDEuNDgsMSAyMS4yNCwyMS4yNCAxLDEgIi8+PC9nPjwvc3ZnPg==);
  background-size: 22px auto;
  background-repeat: no-repeat;
  background-position: 50%;
}

/****************************************************************/
/* BG VIDEO */
/****************************************************************/
.bg-video {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  margin-top: 50px;
  top: 0;
}

.bg-video video {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
}

/****************************************************************/
/* BG PHOTOS */
/****************************************************************/
.bg-photos {
  display: none;
}

/* bg photos */
.bg-photos {
  position: fixed;
  width: 100%;
  height: 100%;
  min-height: 480px;
  left: 0;
  top: 0;
  z-index: 1;
}

.bg-slideshow,
.bg-slideshow:after {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

.bg-slideshow:after {
  content: '';
}

.bg-slideshow {
  list-style: none;
  margin: 0;
  padding: 0;
}

.bg-slideshow li span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: transparent;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  opacity: 0;
  z-index: 0;
  -webkit-backface-visibility: hidden;
  -webkit-animation: imageAnimation 30s linear infinite 0s;
  -moz-animation: imageAnimation 30s linear infinite 0s;
  -o-animation: imageAnimation 30s linear infinite 0s;
  -ms-animation: imageAnimation 30s linear infinite 0s;
  animation: imageAnimation 30s linear infinite 0s;
}

.bg-slideshow li:nth-child(1) span {
  background-image: image-url('backgrounds/bg-model-3.jpg');
}

.bg-slideshow li:nth-child(2) span {
  background-image: image-url('backgrounds/bg-model-5.jpg');
  -webkit-animation-delay: 10s;
  -moz-animation-delay: 10s;
  -o-animation-delay: 10s;
  -ms-animation-delay: 10s;
  animation-delay: 10s;
}

.bg-slideshow li:nth-child(3) span {
  background-image: image-url('backgrounds/bg-model-6.jpg');
  -webkit-animation-delay: 20s;
  -moz-animation-delay: 20s;
  -o-animation-delay: 20s;
  -ms-animation-delay: 20s;
  animation-delay: 20s;
}

/****************************************************************/
/* SCROLL WRAPPER */
/****************************************************************/
.scroll-wrapper {
  position: relative;
  z-index: 2;
}

/****************************************************************/
/* PROMO */
/****************************************************************/
.promo .main-title {
  color: #fff;
  margin: 0;
  padding: 0;
  font-family: 'Muli', 'Avenir Next', sans-serif;
  font-size: 78px;
  font-weight: 400;
  letter-spacing: 30px;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/****************************************************************/
/* ABOUT */
/****************************************************************/
.about {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 80px 0;
}

.about .container {
  height: 100%;
  display: table;
}

.about .row {
  display: table-cell;
  vertical-align: middle;
}

.about .section-title {
  font-size: 31px;
  font-weight: bold;
  text-align: center;
  margin: 0;
}

.about p {
  font-size: 1rem;
  letter-spacing: 0.06em;
  line-height: 2em;
  margin: 54px auto 0;
  text-transform: uppercase;
}

.about p.double-size {
  font-size: 32px;
  line-height: 1.4em;
  font-weight: 300;
}

.about p.word-spacing {
  word-spacing: 0.2em;
}

/****************************************************************/
/* MODELS */
/****************************************************************/
.model {
  height: 600px;
  width: 100%;
  position: relative;
  background-color: #000;
  background-position: 50% 0;
  background-attachment: fixed;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  z-index: 3;
}

.model-1 {
  background-image: image-url('backgrounds/bg-model-1.jpg');
}

.model-2 {
  background-image: image-url('backgrounds/bg-model-2.jpg');
}

.model-3 {
  background-image: image-url('backgrounds/bg-model-4.jpg');
}

/****************************************************************/
/* NEWS & PRESS */
/****************************************************************/
.news-and-press {
  padding: 100px 0;
  background: #000;
}

.art-cnt {
  margin: 0 70px;
}

.news-title-icon-cnt {
  height: 123px;
  text-align: center;
}

.news-title-icon-cnt .vogue {
  margin-top: 20px;
  width: 265px;
  height: 72px;
}

.news-title-icon-cnt .bof {
  margin-top: 5px;
  width: 185px;
  height: 101px;
}

.news-title-icon-cnt .forbes {
  margin-top: 0;
  width: 262px;
  height: 123px;
}

.news-title {
  margin-top: 28px;
  text-transform: uppercase;
  font-family: HelveticaNeue-Thin;
  font-size: 1rem;
  color: #ffffff;
  letter-spacing: 3px;
  line-height: 25px;
}

.news-title-date {
  margin-top: 8px;
  text-transform: uppercase;
  font-size: 13px;
  color: #ffffff;
  line-height: 19px;
  font-family: HelveticaNeue-Medium;
}

.news-text {
  margin-top: 30px;
  font-size: 13px;
  color: #ffffff;
  line-height: 19px;
  font-family: HelveticaNeue-Medium;
}

.news-text p a {
  color: #ffffff;
  text-decoration: underline;
}

.news-title-read-more {
  margin-top: 28px;
  text-align: right;
}

.news-title-read-more a {
  text-decoration: none;
  text-transform: uppercase;
  color: #ffffff;
}

.news-title-read-more a:hover {
  text-decoration: none;
  color: #f47732;
  background-color: transparent;
  outline: none;
}

/****************************************************************/
/* HOW IT WORKS */
/****************************************************************/
.how-it-works {
  padding: 80px 0;
  background: #000;
}

.sticky-wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  bottom: 80px;
  height: 564px;
}

.sticky-relative {
  position: relative;
}

.features-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.features-list li {
  text-align: center;
  /*margin-bottom: 110px;*/
}

.features-list .icon-features {
  display: block;
  margin: 0 auto 10px auto;
}

.features-list h4,
.features-list p {
  font-size: 14px;
  max-width: 260px;
  margin-left: auto;
  margin-right: auto;
}

.features-list p {
  min-height: 100px;
}

.features-list.detailed {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}

.features-list.detailed li {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}

.features-list.detailed li p {
  opacity: 0;
  position: relative;
}

.features-list.detailed li h4 {
  padding-bottom: 20px;
  position: relative;
}

.features-list.detailed li p::after {
  content: '';
  position: absolute;
  left: 50%;
  top: -15px;
  width: 70px;
  height: 1px;
  background: #fff;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.how-it-works .col-sm-4 {
  height: 5400px;
  overflow: visible;
}

.how-it-works .col-sm-4 .features-list.detailed li:nth-of-type(2) {
  top: 200px;
}

.how-it-works .col-sm-4 .features-list.detailed li:nth-of-type(3) {
  top: 400px;
}

.features-list {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  bottom: 80px;
}

.iphone-wrapper {
  width: 271px;
  height: 564px;
  background: image-url('extras/iphone-black.png') 0 0 no-repeat;
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  bottom: 80px;
  margin: 0 auto;
}

.iphone-wrapper-relative {
  position: relative;
  width: 271px;
  height: 564px;
}

.iphone-wrapper .mask {
  background: #000;
  height: 430px;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: opacity 0s ease 0s, visibility 0.5s ease-in-out 0s;
  transition: opacity 0s ease 0s, visibility 0.5s ease-in-out 0s;
  width: 237px;
  z-index: 10;
}

.iphone-wrapper .canvas {
  height: 342px;
  position: relative;
  width: 2195px;
}

.iphone-wrapper .page {
  float: left;
  height: 430px;
  width: 237px;
}

.iphone-wrapper .page img {
  width: 100%;
}

/****************************************************************/
/* CONTACT */
/****************************************************************/
.contact {
  background: #000 image-url('backgrounds/bg-contact.png') repeat scroll 0 0;
  padding: 450px 0 300px 0;
  position: relative;
}

.contact .row {
  display: table;
  width: 100%;
}

.contact .row [class^='col'] {
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  padding-top: 20px;
  padding-bottom: 20px;
  display: table-cell;
  height: 100%;
  float: none;
  vertical-align: middle;
}

.contact .row [class^='col']:first-of-type {
  border-left: none;
}

.contact-social-list {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.contact-social-list li {
  display: inline-block;
  padding: 0 20px;
}

.contact-social-list .social-icon {
  width: 64px;
  height: auto;
}

.contact .btn-app-store {
  display: inline-block;
  width: 109px;
  height: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-indent: -9999em;
  background: image-url('extras/app-store-btn-@2x.png') 50% / auto 40px no-repeat;
  opacity: 1;
}

.contact-social-title,
.contact-download-title,
.contact-info-title,
.contact-newsletter-title {
  color: #f47732;
  font-family: 'Avenir Next', Lato, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1.875rem;
  letter-spacing: 4px;
  text-align: center;
  margin: 0 0 20px 0;
}

.contact-info-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.contact-info-list li {
  font-size: 1rem;
  text-align: center;
  margin-bottom: 20px;
}

.contact-info-list li:last-of-type {
  margin-bottom: 0;
}

.contact-info-list a {
  display: block;
  margin-top: 2px;
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.contact-info-list a:hover {
  color: #f47732;
  text-decoration: none;
}

.contact-newsletter-info {
  color: #fff;
  font-size: 13px;
  letter-spacing: 0.3em;
  text-align: center;
  padding: 0 50px;
}

.newsletter-form {
  width: 100%;
  margin: 0;
  padding: 10px 0;
  position: relative;
  white-space: nowrap;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.newsletter-email {
  height: 34px !important;
  width: 240px;
  margin-right: 4px;
  border: none;
  background: #fff;
  color: #222;
  padding: 0 10px;
  text-transform: uppercase;
  border-radius: 0;
}

.newsletter-email:focus {
  outline: none;
  box-shadow: none;
}

.newsletter-bttn {
  width: 34px;
  height: 34px;
  border-radius: 0;
  border: none;
  overflow: hidden;
  text-indent: -9999em;
  background-color: #fff;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSI0M3B4IiB2aWV3Qm94PSIwIDAgMjQgNDMiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiPiAgICAgICAgPHRpdGxlPlNoYXBlPC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxkZWZzPjwvZGVmcz4gICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc2tldGNoOnR5cGU9Ik1TUGFnZSI+ICAgICAgICA8ZyBpZD0iaWNvbi1hcnJvdy11cCIgc2tldGNoOnR5cGU9Ik1TTGF5ZXJHcm91cCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTkuMDAwMDAwLCAxMC4wMDAwMDApIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZT0iIzAwMDAwMCI+ICAgICAgICAgICAgPGcgaWQ9IlBhZ2UtMSIgc2tldGNoOnR5cGU9Ik1TU2hhcGVHcm91cCI+ICAgICAgICAgICAgICAgIDxnIGlkPSJpY29uLWNoZXZyb24tZG93biIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjAuNTAwMDAwLCAxMS41MDAwMDApIHJvdGF0ZSgxODAuMDAwMDAwKSB0cmFuc2xhdGUoLTIwLjUwMDAwMCwgLTExLjUwMDAwMCkgdHJhbnNsYXRlKDAuMDAwMDAwLCAxLjAwMDAwMCkiPiAgICAgICAgICAgICAgICAgICAgPGcgaWQ9IkNsaXBwZWQiPiAgICAgICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik00MC40OCwwIEwyMC4yNCwyMC4yNCBMMy41NTI3MTM2OGUtMTUsMCIgaWQ9IlNoYXBlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyMC4yNDAwMDAsIDEwLjEyMDAwMCkgcm90YXRlKC0yNzAuMDAwMDAwKSB0cmFuc2xhdGUoLTIwLjI0MDAwMCwgLTEwLjEyMDAwMCkgIj48L3BhdGg+ICAgICAgICAgICAgICAgICAgICA8L2c+ICAgICAgICAgICAgICAgIDwvZz4gICAgICAgICAgICA8L2c+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 12px auto;
  vertical-align: middle;
}

.newsletter-bttn:active {
  background-color: #eee;
}

.newsletter-bttn:focus {
  box-shadow: none;
  outline: none;
}

.newsletter-error-message,
.newsletter-success-message {
  display: none;
  margin: 10px 0 0 0;
  position: absolute;
  margin-top: 55px;
}

.newsletter-error-message {
  color: #d30909;
}

.newsletter-success-message {
  color: #09d339;
}

.contact .link-gotop {
  position: absolute;
  left: 50%;
  bottom: 30px;
  display: block;
  height: 44px;
  width: 44px;
  margin-left: -22px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyB3aWR0aD0iNDNweCIgaGVpZ2h0PSIyM3B4IiB2aWV3Qm94PSIwIDAgNDMgMjMiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiPiAgICAgICAgPHRpdGxlPlNoYXBlPC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxkZWZzPjwvZGVmcz4gICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc2tldGNoOnR5cGU9Ik1TUGFnZSI+ICAgICAgICA8ZyBpZD0iaWNvbi1jaGV2cm9uLWRvd24iIHNrZXRjaDp0eXBlPSJNU0xheWVyR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIxLjUwMDAwMCwgMTEuNTAwMDAwKSByb3RhdGUoLTE4MC4wMDAwMDApIHRyYW5zbGF0ZSgtMjEuNTAwMDAwLCAtMTEuNTAwMDAwKSAiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlPSIjRkZGRkZGIj4gICAgICAgICAgICA8ZyBpZD0iQ2xpcHBlZCIgc2tldGNoOnR5cGU9Ik1TU2hhcGVHcm91cCI+ICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik00MS40OCwxIEwyMS4yNCwyMS4yNCBMMSwxIiBpZD0iU2hhcGUiPjwvcGF0aD4gICAgICAgICAgICA8L2c+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=);
  background-size: 44px auto;
  background-repeat: no-repeat;
  background-position: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-indent: -9999em;
  opacity: 0.7;
  /* animation removed because bug with fixed bg images in chrome  */
  /*
    -webkit-animation: fade-move-up 2s ease-in-out infinite;
    -moz-animation: fade-move-up 2s ease-in-out infinite;
    -o-animation: fade-move-up 2s ease-in-out infinite;
    animation: fade-move-up 2s ease-in-out infinite;
    */
}

.contact .link-gotop:hover {
  opacity: 1;
}

/****************************************************************/
/* FAQ */
/****************************************************************/
.faq {
  background: #000;
  padding: 80px 0;
  position: relative;
}

.faq .section-title {
  text-align: center;
  color: #f47732;
  font-family: 'Avenir Next', Lato, Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 11px;
  margin: 0 0 60px 0;
}

.faq [class^='col'] {
  padding: 0 40px;
}

.faq-item-question {
  font-size: 14px;
  line-height: 1.3em;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  font-weight: 400;
  color: #f47732;
}

.faq-item-answer + .faq-item-question {
  padding-top: 10px;
}

/****************************************************************/
/* ASIDE NAV */
/****************************************************************/
.aside-nav {
  position: fixed;
  top: 50%;
  right: 40px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 20;
  display: none;
}

.aside-nav .nav-item {
  margin-bottom: 20px;
}

.aside-nav .nav-item:last-child {
  float: none;
  margin: 0 !important;
}

.aside-nav .nav-item a {
  display: block;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-indent: -9999em;
}

.aside-nav .nav-item a:focus,
.aside-nav .nav-item a:active {
  outline: none;
}

.aside-nav .nav-item.active a {
  background: #f47732;
}

/****************************************************************/
/* FOOTER */
/****************************************************************/
.footer {
  background-color: #181818;
  color: #c9c9c9;
  font-size: 9px;
  padding: 25px 0;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  width: 100%;
  float: left;
}

.hidden-xs {
  color: #c9c9c9;
}

.footer p {
  margin: 0;
}

.footer a {
  color: #c9c9c9;
}

.footer .copyright-text a:hover {
  color: #eee;
}

.footer .copyright-text a:hover,
.footer .copyright-text a:focus,
.footer .copyright-text a:active {
  text-decoration: none;
}

.footer .designed-by {
  text-align: right;
}

.footer .designed-by a {
  text-decoration: underline;
}

/****************************************************************/
/* CONTENT PAGES */
/****************************************************************/
.content-page {
  padding-top: 100px;
  transition: padding-top 1.2s, transform 1.2s;
}

.content-page .header-main {
  background: rgba(0, 0, 0, 0.8);
}

.content-page .logo {
  opacity: 1;
}

.content-page .header-main .main-nav .nav .nav-item .scrollto {
  opacity: 1;
}

.content-page article {
  color: #000;
  padding: 30px;
}

.content-page article a {
  color: #f47732;
}

.content-page .underline {
  color: #000;
}

.content-page .page-title {
  text-align: center;
  padding-bottom: 1em;
  color: #f47732;
  text-transform: uppercase;
  letter-spacing: 0.3em;
}

.content-page h3 {
  font-size: 1.25rem;
  text-align: center;
  padding: 1.5em 0 0.5em;
}

.content-page p,
.content-page ol,
.content-page ul {
  margin-bottom: 1.5em;
}

.content-page ol li {
  padding-left: 0.5em;
}

.note-wrapper {
  padding: 40px;
  border: 1px solid #444;
  margin-bottom: 1.5em;
}

.note-wrapper p:last-of-type {
  margin-bottom: 0;
}

.content-page h3.uppercase,
.content-page strong.uppercase {
  color: #000;
}

/****************************************************************/
/* ANIMATIONS */
/****************************************************************/
/* fade move down */
@-webkit-keyframes fade-move-down {
  0% {
    -webkit-transform: translate(0, -20px);
    transform: translate(0, -20px);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
  50% {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 20px);
    transform: translate(0, 20px);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
}

@-moz-keyframes fade-move-down {
  0% {
    -moz-transform: translate(0, -20px);
    transform: translate(0, -20px);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
  50% {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
  }
  100% {
    -moz-transform: translate(0, 20px);
    transform: translate(0, 20px);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
}

@-o-keyframes fade-move-down {
  0% {
    -o-transform: translate(0, -20px);
    transform: translate(0, -20px);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
  50% {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
  }
  100% {
    -o-transform: translate(0, 20px);
    transform: translate(0, 20px);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
}

@keyframes fade-move-down {
  0% {
    -webkit-transform: translate(0, -20px);
    -moz-transform: translate(0, -20px);
    -o-transform: translate(0, -20px);
    transform: translate(0, -20px);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
  50% {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 20px);
    -moz-transform: translate(0, 20px);
    -o-transform: translate(0, 20px);
    transform: translate(0, 20px);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
}

/* fade move up */
@-webkit-keyframes fade-move-up {
  0% {
    -webkit-transform: translate(0, 20px);
    transform: translate(0, 20px);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
  50% {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, -20px);
    transform: translate(0, -20px);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
}

@-moz-keyframes fade-move-up {
  0% {
    -moz-transform: translate(0, 20px);
    transform: translate(0, 20px);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
  50% {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
  }
  100% {
    -moz-transform: translate(0, -20px);
    transform: translate(0, -20px);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
}

@-o-keyframes fade-move-up {
  0% {
    -o-transform: translate(0, 20px);
    transform: translate(0, 20px);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
  50% {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
  }
  100% {
    -o-transform: translate(0, -20px);
    transform: translate(0, -20px);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
}

@keyframes fade-move-up {
  0% {
    -webkit-transform: translate(0, 20px);
    -moz-transform: translate(0, 20px);
    -o-transform: translate(0, 20px);
    transform: translate(0, 20px);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
  50% {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, -20px);
    -moz-transform: translate(0, -20px);
    -o-transform: translate(0, -20px);
    transform: translate(0, -20px);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
}

/* animation for the slideshow images */
@-webkit-keyframes imageAnimation {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: ease-in;
  }
  8% {
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
  }
  17% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes imageAnimation {
  0% {
    opacity: 0;
    -moz-animation-timing-function: ease-in;
  }
  8% {
    opacity: 1;
    -moz-animation-timing-function: ease-out;
  }
  17% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes imageAnimation {
  0% {
    opacity: 0;
    -o-animation-timing-function: ease-in;
  }
  8% {
    opacity: 1;
    -o-animation-timing-function: ease-out;
  }
  17% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes imageAnimation {
  0% {
    opacity: 0;
    -ms-animation-timing-function: ease-in;
  }
  8% {
    opacity: 1;
    -ms-animation-timing-function: ease-out;
  }
  17% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes imageAnimation {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
  }
  8% {
    opacity: 1;
    animation-timing-function: ease-out;
  }
  17% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.animated {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInUp {
  0% {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    -webkit-transform: translateY(60px) scale(1, 1);
    transform: translateY(60px) scale(1, 1);
    -webkit-transition: opacity 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
    transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
  }
  100% {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
    -webkit-transform: perspective(1600px) rotateX(0deg) scale(1, 1) translateY(0px);
    transform: perspective(1600px) rotateX(0deg) scale(1, 1) translateY(0px);
    -webkit-transition: opacity 0.5s ease 0s, -webkit-transform 0.5s ease 0s;
    transition: opacity 0.5s ease 0s, transform 0.5s ease 0s;
  }
}

@-moz-keyframes fadeInUp {
  0% {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    -moz-transform: translateY(60px) scale(1, 1);
    transform: translateY(60px) scale(1, 1);
    -moz-transition: opacity 0.3s ease 0s, -moz-transform 0.3s ease 0s;
    transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
  }
  100% {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
    -moz-transform: perspective(1600px) rotateX(0deg) scale(1, 1) translateY(0px);
    transform: perspective(1600px) rotateX(0deg) scale(1, 1) translateY(0px);
    -moz-transition: opacity 0.5s ease 0s, -moz-transform 0.5s ease 0s;
    transition: opacity 0.5s ease 0s, transform 0.5s ease 0s;
  }
}

@-o-keyframes fadeInUp {
  0% {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    -o-transform: translateY(60px) scale(1, 1);
    transform: translateY(60px) scale(1, 1);
    -o-transition: opacity 0.3s ease 0s, -o-transform 0.3s ease 0s;
    transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
  }
  100% {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
    transform: perspective(1600px) rotateX(0deg) scale(1, 1) translateY(0px);
    -o-transition: opacity 0.5s ease 0s, -o-transform 0.5s ease 0s;
    transition: opacity 0.5s ease 0s, transform 0.5s ease 0s;
  }
}

@keyframes fadeInUp {
  0% {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    -webkit-transform: translateY(60px) scale(1, 1);
    -moz-transform: translateY(60px) scale(1, 1);
    -o-transform: translateY(60px) scale(1, 1);
    transform: translateY(60px) scale(1, 1);
    -webkit-transition: opacity 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
    -moz-transition: opacity 0.3s ease 0s, -moz-transform 0.3s ease 0s;
    -o-transition: opacity 0.3s ease 0s, -o-transform 0.3s ease 0s;
    transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
  }
  100% {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$alpha*100)';
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
    -webkit-transform: perspective(1600px) rotateX(0deg) scale(1, 1) translateY(0px);
    -moz-transform: perspective(1600px) rotateX(0deg) scale(1, 1) translateY(0px);
    transform: perspective(1600px) rotateX(0deg) scale(1, 1) translateY(0px);
    -webkit-transition: opacity 0.5s ease 0s, -webkit-transform 0.5s ease 0s;
    -moz-transition: opacity 0.5s ease 0s, -moz-transform 0.5s ease 0s;
    -o-transition: opacity 0.5s ease 0s, -o-transform 0.5s ease 0s;
    transition: opacity 0.5s ease 0s, transform 0.5s ease 0s;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

/****************************************************************/
/* TABLETS & MOBILE */
/****************************************************************/
/* TABLETS */
@media screen and (max-width: 1115px) {
  .header-main .main-nav .nav .nav-item a {
    padding: 0 3px !important;
    margin-left: 3px !important;
  }
  .header-main .main-nav .nav .nav-item {
    margin-right: 2px !important;
  }
}

@media screen and (max-width: 991px) {
  body {
    min-width: 320px;
  }
  .m-top-mobile {
    margin-top: 80px;
  }
  .news-title-read-more a {
    text-decoration: none;
    text-transform: uppercase;
    color: #f47732;
    background-color: transparent;
    outline: none;
  }
  .news-title-icon-cnt {
    margin-top: 30px;
  }
  .art-cnt {
    margin: 0 10px;
  }
  /* collapse nav */
  .navbar-header {
    float: none;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: none;
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-nav {
    float: none !important;
  }
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-collapse.collapse.in {
    display: block !important;
  }
  .navbar-toggle .icon-bar {
    background: #fff;
  }
  .header-main .main-nav .nav .nav-item .scrollto {
    opacity: 1 !important;
  }
  /* header */
  .header-main {
    background: black;
    padding: 10px 0;
    -webkit-transition: background 0.3s ease-in-out;
    transition: background 0.4s linear;
  }
  .header-main .container-fluid {
    position: relative;
  }
  .logo {
    float: right;
    position: relative;
    z-index: 1;
  }
  .logo a {
    font-size: 1.25rem;
    line-height: 30px;
    letter-spacing: 8px;
  }
  .main-nav {
    position: absolute;
    left: 15px;
    right: 15px;
    top: 0;
  }
  .main-nav .navbar-toggle {
    border: none;
    background: transparent;
    margin: 0 0 0 15px;
    padding: 6px 0;
    float: left;
  }
  .main-nav .navbar-toggle .icon-bar {
    background: #f47732;
  }
  .main-nav .navbar-toggle.collapsed .icon-bar {
    background: #fff;
  }
  .main-nav .navbar-brand {
    color: #fff;
    padding: 15px 0;
  }
  .main-nav .navbar-collapse.collapse.in {
    background: #000;
    opacity: 1;
    margin-left: -31px;
    margin-right: -31px;
    border-bottom: 4px solid #f47732;
    margin-top: 14px;
  }
  .main-nav .navbar-collapse.collapse.in .nav {
    margin: 0 -15px;
  }
  .main-nav .navbar-collapse .nav-item {
    margin: 0 15px !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .main-nav .navbar-collapse .nav-item:last-of-type {
    border-bottom: none;
  }
  .main-nav .navbar-collapse .nav-item a {
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
    padding: 15px;
  }
  .main-nav .navbar-collapse {
    opacity: 0;
    -webkit-transition: background 0.3s ease-in-out, opacity 0.3s ease-in-out;
    -ms-transition: background 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transition: background 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
  .main-nav .navbar-collapse .nav-item a:hover,
  .main-nav .navbar-collapse .nav-item a:focus,
  .main-nav .navbar-collapse .nav-item a:active {
    background: transparent;
    color: #f47732;
    outline: none;
  }
  .header-main .main-nav .nav .nav-item .scrollto {
    opacity: 1;
  }
  /* about */
  .about p.double-size {
    font-size: 1.375rem;
  }
  /* promo */
  .promo .main-title {
    font-size: 64px;
    letter-spacing: 24px;
  }
  /* hot it works */
  .iphone-wrapper {
    margin-left: -24px;
  }
  /* contact */
  .contact-social-title,
  .contact-download-title,
  .contact-info-title,
  .contact-newsletter-title {
    font-size: 24px;
  }
  .contact-social-list li,
  .download-col {
    margin-bottom: 20px;
  }
  .contact-social-list li:last-of-type {
    margin-bottom: 0;
  }
  /* faq */
  .faq [class^='col'] {
    padding: 0 15px;
  }
}

/* MOBILES */
@media screen and (max-width: 767px) {
  .header-main {
    -webkit-box-shadow: 0 0 40px 40px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 40px 40px rgba(0, 0, 0, 0.6);
  }
  .bg-photos {
    display: block;
  }
  /* models */
  .model {
    display: none;
  }
  /* about */
  .about {
    padding: 60px 0;
  }
  .about .section-title {
    font-size: 1.375rem;
  }
  .about p {
    font-size: 14px;
    line-height: 1.5em;
    margin: 1.5em 0 0 0;
  }
  .about p.double-size {
    font-size: 18px;
  }
  /* how it works */
  .how-it-works {
    padding: 60px 0 0 0;
  }
  .how-it-works .col-sm-4 {
    height: auto;
  }
  .features-list {
    position: static !important;
  }
  .features-list.detailed li {
    margin-top: 0;
    margin-bottom: 60px;
    position: static !important;
    opacity: 1 !important;
  }
  .features-list h4,
  .features-list p {
    max-width: 220px;
    margin-left: auto;
    margin-right: auto;
    min-height: 1px;
  }
  .features-list.detailed li p {
    opacity: 1 !important;
    position: relative;
  }
  .iphone-wrapper {
    position: relative !important;
    display: block !important;
    margin: 0 auto 60px auto !important;
    top: 0 !important;
    bottom: 0 !important;
  }
  .sticky-wrapper {
    height: auto;
  }
  /* contact */
  .contact {
    padding: 60px 0;
  }
  .contact .row {
    width: auto;
    display: block;
  }
  .contact .row [class^='col'] {
    display: block;
    border-left: none;
    padding-bottom: 40px;
  }
  .contact .row [class^='col']:last-of-type {
    padding-bottom: 0;
  }
  .contact-social-title,
  .contact-download-title,
  .contact-info-title,
  .contact-newsletter-title {
    font-size: 1.25rem;
    letter-spacing: 0.1em;
  }
  .contact-social-list li {
    padding: 0 15px;
    margin-bottom: 0;
  }
  .contact-social-list a {
    display: inline-block;
    width: 48px;
    height: 48px;
  }
  .contact-social-list a img {
    width: 100%;
  }
  .contact-info-list li {
    font-size: 14px;
  }
  .contact-newsletter-info {
    font-size: 12px;
    letter-spacing: 0.1em;
  }
  .newsletter-form {
    padding-bottom: 60px;
  }
  /* faq */
  .faq {
    padding: 60px 0;
  }
  .faq .section-title {
    font-size: 1.25rem;
    letter-spacing: 0.1em;
    margin: 0 auto 40px;
    max-width: 220px;
  }
  .faq-item-question {
    font-size: 14px;
    margin: 0 auto 10px;
    max-width: 360px;
  }
  .faq-item-answer {
    font-size: 14px;
    margin: 0 auto 40px;
    max-width: 360px;
  }
  .faq-more-btn {
    border: 1px solid #f47732;
    height: 40px;
    padding: 0 20px;
    line-height: 40px;
    display: inline-block;
    margin: 0 auto;
    text-transform: uppercase;
    color: #f47732;
    margin-bottom: 40px;
  }
  .faq-more-btn:hover,
  .faq-more-btn:focus,
  .faq-more-btn:active {
    text-decoration: none;
    color: #fff;
    border-color: #fff;
  }
  /* footer */
  .footer .copyright-text a {
    display: block;
    margin: 10px auto;
    text-align: center;
  }
  .footer .designed-by {
    text-align: center;
  }
  /* aside nav */
  .aside-nav {
    display: none !important;
  }
}

@media screen and (max-width: 640px) {
  /* promo */
  .promo .main-title {
    font-size: 48px;
    letter-spacing: 12px;
  }
  /* about */
  .about {
    height: auto;
    min-height: 1px !important;
  }
}

@media screen and (max-width: 480px) {
  /* layout helpers */
  .screen {
    min-height: 480px;
  }
  /* promo */
  .promo .main-title {
    font-size: 1.875rem;
    letter-spacing: 8px;
  }
  /* faq */
  .faq-item-question {
    max-width: 280px;
  }
  .faq-item-answer {
    max-width: 280px;
  }
  /* content pages */
  .content-page ol,
  .content-page ul {
    padding-left: 1em;
  }
}

/****************************************************************/
/* FIXES */
/****************************************************************/
/* fix for video on ipad */
.ipad .bg-video {
  display: none !important;
}

.ipad .bg-photos {
  display: block !important;
}

/* fix position:sticky in chrome */
@media screen and (min-width: 768px) {
  .how-it-works .col-sm-4:nth-of-type(2) .fixed-col,
  .how-it-works .col-sm-4:nth-of-type(3) .fixed-col {
    width: 250px;
    padding: 0 20px;
  }
  .how-it-works .iphone-wrapper.fixed-col {
    margin-left: -12px;
  }
  .how-it-works .iphone-wrapper.fixed-col {
    background-position: 0 0;
  }
  .how-it-works .iphone-wrapper.fixed-col .iphone-wrapper-relative {
    margin: 0 auto;
  }
}

@media screen and (min-width: 992px) {
  .how-it-works .col-sm-4:nth-of-type(2) .fixed-col,
  .how-it-works .col-sm-4:nth-of-type(3) .fixed-col,
  .how-it-works .iphone-wrapper.fixed-col {
    width: 390px;
  }
  .how-it-works .iphone-wrapper.fixed-col {
    background-position: 50% 0;
  }
  .how-it-works .iphone-wrapper.fixed-col .iphone-wrapper-relative {
    margin: 0 auto;
  }
}

.talent_view {
  display: table;
  width: 100%;
  height: 100%;
}
.talent_view .talent_view_header {
  font-family: 'Avenir-Heavy';
  font-size: 2.125rem;
  letter-spacing: 2px;
  width: 100%;
  height: 100px;
  padding-top: 40px;
  float: left;
  margin-bottom: 30px;
  padding-left: 40px;
}
.talent_view .people_grid {
  display: table-cell;
  width: 100%;
  height: 240px;
  float: left;
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.jobs_calendar {
  height: 100%;
  overflow: scroll;
}
.jobs_calendar .jobs_calendar_month_container {
  width: 100%;
}
.jobs_calendar .jobs_calendar_day_container {
  height: 200px;
  width: 14.2%;
  border-color: white;
  border-width: 1px;
  border-style: solid;
  float: left;
  position: relative;
}
.jobs_calendar .jobs_calendar_day_container .jobs_calendar_day_label {
  position: absolute;
  left: 10px;
  top: 10px;
}
.jobs_calendar .jobs_calendar_header {
  width: calc(100% - 200px);
  position: fixed;
  height: 50px;
  top: 100px;
  background-color: #111111;
}
.jobs_calendar .jobs_calendar_header .jobs_calendar_header_title {
  text-align: center;
  font-size: 1.875rem;
}

.labeled_image_block {
  float: left;
  position: relative;
}
.labeled_image_block .labeled_image_block_image {
  width: 100%;
  height: 100%;
  background-size: cover;
}
.labeled_image_block .labeled_image_block_gradient {
  position: absolute;
  bottom: 0px;
  height: 130px;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
}
.labeled_image_block .labeled_image_block_gradient--black {
  background: transparent;
}
.labeled_image_block .labeled_image_block_small_copy {
  display: none;
  font-family: 'Avenir';
  padding-left: 40px;
  padding-right: 40px;
  letter-spacing: 1pl87x;
  position: relative;
  padding-bottom: 13px;
  padding-top: 15px;
  border-bottom: 1px solid black;
  font-size: 10px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  font-weight: 800;
  float: left;
}
.labeled_image_block .labeled_image_block_small_copy .line {
  display: inline-Block;
  width: 15px;
  height: 10px;
  border-bottom: 1px solid;
  margin-left: 10px;
  position: absolute;
  top: 12px;
}
.labeled_image_block .labeled_image_block_small_copy .italic {
  margin-bottom: 0px;
  display: inline-block;
  margin-left: 37px;
  font-family: 'Caslon';
  text-transform: initial;
  font-size: 12px;
  font-weight: 500;
  -webkit-font-smoothing: auto;
}
.labeled_image_block .labeled_image_block_small_copy .italic:hover {
  text-decoration: underline;
}
.labeled_image_block .labeled_image_block_small_copy .plus {
  width: 25px;
  position: absolute;
  top: 9px;
  margin-left: 2px;
}
.labeled_image_block .labeled_image_block_small_copy--black {
  background: black;
  color: white;
  border-bottom: 1px solid white;
}
.labeled_image_block .labeled_image_block_large_copy {
  margin-left: 40px;
  position: absolute;
  letter-spacing: 2px;
  bottom: 15px;
  font-family: 'Avenir-Black', 'Lato';
  font-weight: 900;
  font-size: 3.5em;
}
.labeled_image_block .labeled_image_block_italic_copy {
  position: absolute;
  bottom: 80px;
  font-family: 'Caslon';
  font-size: 1.5em;
  letter-spacing: 0px;
  margin-left: 40px;
}
.labeled_image_block .labeled_image_block_under_copy {
  position: absolute;
  bottom: 5px;
  font-family: 'Caslon';
  font-size: 1.5em;
  letter-spacing: 0px;
  margin-left: 40px;
}
.labeled_image_block .labeled_image_block_side_copy {
  margin-right: 40px;
  position: absolute;
  opacity: 0.5;
  letter-spacing: 1px;
  right: 20px;
  bottom: 20px;
  font-family: 'Avenir-Heavy', 'Lato';
  font-weight: 800;
  font-size: 15px;
}

.user_snapshot_label {
  -webkit-font-smoothing: antialiased;
}

.sidebar_button {
  width: 100%;
  height: 40px;
  line-height: 40px;
  float: left;
  border-bottom-width: 1px;
  border-bottom-color: #333;
  border-bottom-style: solid;
  cursor: pointer;
  position: relative;
  color: white;
}
.sidebar_button .sidebar_button_text {
  width: 100%;
  margin-left: 37px;
  font-size: 13.5px;
  font-family: 'Avenir-Heavy', 'Lato';
  font-weight: 500;
  letter-spacing: 1px;
}

.sidebar_button:not(.sidebar_button--selected):hover {
  background-color: #222222;
}

.sidebar_button--selected {
  color: #fa4726;
}

.sidebar_subcategory_button {
  width: 100%;
  height: 40px;
  line-height: 40px;
  float: left;
  border-bottom-width: 1px;
  border-bottom-color: #222;
  border-right: 1px solid #222;
  border-bottom-style: solid;
  cursor: pointer;
  color: #bbb;
}
.sidebar_subcategory_button .sidebar_subcategory_button_text {
  width: 100%;
  margin-left: 60px;
  font-size: 13.5px;
  font-family: 'Avenir-Light';
  font-weight: 300;
  letter-spacing: 1px;
}

.sidebar_subcategory_button:not(.sidebar_button--selected):hover {
  background-color: #222222;
}

.sidebar_subcategory_button--selected {
  color: #fa4726;
}

.sc_dropdown {
  border: 1px solid white;
  background-color: black;
  border-radius: 0px;
  width: 100%;
  appearance: none;
  font-size: 1rem;
  padding-left: 10px;
  text-align-last: center;
  position: relative;
  height: 45px;
}
.sc_dropdown .sc_dropdown_option {
  appearance: none;
}

.user_basic_snapshot {
  background: #0b0b0b;
  width: 100%;
  padding-bottom: 20px;
}
.user_basic_snapshot:hover a {
  text-decoration: none;
}
.user_basic_snapshot a {
  text-decoration: none;
  color: white;
}
.user_basic_snapshot .user_basic_images {
  display: flex;
}
.user_basic_snapshot .user_basic_images .user_basic_snapshot__image_wrapper {
  margin-bottom: 10px;
}
.user_basic_snapshot .user_basic_info_container {
  display: flex;
}
.user_basic_snapshot .user_basic_info_container .user_project_options {
  margin-right: 20px;
  font-family: Caslon;
  display: flex;
  margin-top: 5px;
}
.user_basic_snapshot .user_basic_info_container .user_project_options a {
  color: white;
  text-decoration: none;
}
.user_basic_snapshot .user_basic_info_container .user_project_options a:hover {
  color: #fa4726;
}
.user_basic_snapshot .user_basic_info_container .user_basic_info {
  margin-right: auto;
}
.user_basic_snapshot .user_basic_info_container .user_basic_info p.user_name {
  font-family: 'Avenir-Heavy', 'Lato';
  font-weight: 800;
  font-size: 14px;
  letter-spacing: 1px;
  margin-left: 10px;
  margin-bottom: 0px;
  margin-right: 10px;
  margin-top: 5px;
  color: white;
}
.user_basic_snapshot .user_basic_info_container .user_basic_info p.user_company {
  font-family: 'Avenir-Medium', 'Lato';
  font-weight: 500;
  color: grey;
  margin-left: 10px;
}

@keyframes shrink {
  0% {
    height: 100px;
  }
  100% {
    height: 0px;
  }
}

.user_snapshot_recommended--hidden {
  animation: shrink 0.3s forwards;
  height: 0px;
}

.user_snapshot__image_container {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

.user_snapshot_requests {
  flex-grow: 1;
  flex-basis: 650px;
  max-width: 100%;
  z-index: 2;
  cursor: pointer;
  width: 100px;
  border: 1px solid black;
}
@media (min-width: 1300px) {
  .user_snapshot_requests {
    max-width: 50%;
    flex-basis: 50%;
  }
}
@media (min-width: 1950px) {
  .user_snapshot_requests {
    max-width: 33.33333%;
    flex-basis: 33.33333%;
  }
}
@media (min-width: 2600px) {
  .user_snapshot_requests {
    max-width: 25%;
    flex-basis: 25%;
  }
}
@media (min-width: 3250px) {
  .user_snapshot_requests {
    max-width: 20%;
    flex-basis: 20%;
  }
}

.user_snapshot {
  flex-grow: 1;
  flex-basis: 300px;
  max-width: 100%;
  z-index: 2;
  cursor: pointer;
  width: 100px;
  border: 1px solid black;
}
@media (min-width: 600px) {
  .user_snapshot {
    max-width: 50%;
    flex-basis: 50%;
  }
}
@media (min-width: 900px) {
  .user_snapshot {
    max-width: 33.33333%;
    flex-basis: 33.33333%;
  }
}
@media (min-width: 1200px) {
  .user_snapshot {
    max-width: 25%;
    flex-basis: 25%;
  }
}
@media (min-width: 1500px) {
  .user_snapshot {
    max-width: 20%;
    flex-basis: 20%;
  }
}
@media (min-width: 1800px) {
  .user_snapshot {
    max-width: 16.66667%;
    flex-basis: 16.66667%;
  }
}
@media (min-width: 2100px) {
  .user_snapshot {
    max-width: 14.28571%;
    flex-basis: 14.28571%;
  }
}
@media (min-width: 2400px) {
  .user_snapshot {
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
}
.user_snapshot .user_snapshot__cover {
  height: calc(100% - 10px);
  width: calc(100% - 20px);
  position: absolute;
  top: 0px;
  margin-left: 10px;
  margin-top: 10px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0);
}
.user_snapshot .user_snapshot_body:hover .user_snapshot__cover {
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  border: 1px solid white;
}
.user_snapshot .user_snapshot_body:hover .user_snapshot__text_container,
.user_snapshot .user_snapshot_body:hover .user_snapshot__image_container {
  text-decoration: none;
  color: #fa4726;
}
.user_snapshot .user_snapshot_body:hover .user_snapshot__text_container .user_snapshot__image,
.user_snapshot .user_snapshot_body:hover .user_snapshot__image_container .user_snapshot__image {
  opacity: 0.45;
  transition: opacity 0.3s;
}
.user_snapshot .user_snapshot_body {
  text-decoration: none;
  color: inherit;
}
.user_snapshot .user_snapshot_body .user_snapshot__image_request {
  width: 50%;
  height: auto;
  float: left;
}
.user_snapshot .user_snapshot_body .user_snapshot__image {
  width: 100%;
  height: auto;
  float: left;
}
.user_snapshot .user_snapshot_body .user_snapshot__text_container {
  float: left;
  height: 120px;
  color: #ddd;
  margin-left: 10px;
  margin-right: 10px;
  width: calc(100% - 20px);
}
.user_snapshot .user_snapshot_body .user_snapshot__text_container .user_snapshot__text_section {
  float: left;
  margin-left: 10px;
  margin-right: 10px;
  width: calc(100% - 20px);
  margin-top: 10px;
  margin-bottom: 20px;
}

.user_snapshot__text_body {
  color: #777777;
}

.user_snapshot_body:hover .user_snapshot__text_body {
  color: white;
}

.image_wrapper {
  width: 100%;
  position: relative;
  padding-top: 0px;
  float: left;
}

.fallback_container {
  background-color: #111111;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fallback_container .fallback_circle {
  height: 50px;
  width: 50px;
  border-radius: 25px;
  border: 2px solid #999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fallback_container .fallback_circle .fallback_s {
  height: 50px;
  width: 100%;
  padding-top: 0px;
  line-height: 50px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #999999;
  font-size: 1.875rem;
  user-select: none;
}

.flex-wrap400 {
  flex-grow: 1;
  flex-basis: 400px;
  max-width: 100%;
}
@media (min-width: 800px) {
  .flex-wrap400 {
    max-width: 50%;
    flex-basis: 50%;
  }
}
@media (min-width: 1200px) {
  .flex-wrap400 {
    max-width: 33.33333%;
    flex-basis: 33.33333%;
  }
}
@media (min-width: 1600px) {
  .flex-wrap400 {
    max-width: 25%;
    flex-basis: 25%;
  }
}
@media (min-width: 2000px) {
  .flex-wrap400 {
    max-width: 20%;
    flex-basis: 20%;
  }
}
@media (min-width: 2400px) {
  .flex-wrap400 {
    max-width: 16.66667%;
    flex-basis: 16.66667%;
  }
}

.flex-wrap230 {
  flex-grow: 1;
  flex-basis: 230px;
  max-width: 100%;
}
@media (min-width: 460px) {
  .flex-wrap230 {
    max-width: 50%;
    flex-basis: 50%;
  }
}
@media (min-width: 690px) {
  .flex-wrap230 {
    max-width: 33.33333%;
    flex-basis: 33.33333%;
  }
}
@media (min-width: 920px) {
  .flex-wrap230 {
    max-width: 25%;
    flex-basis: 25%;
  }
}
@media (min-width: 1150px) {
  .flex-wrap230 {
    max-width: 20%;
    flex-basis: 20%;
  }
}
@media (min-width: 1380px) {
  .flex-wrap230 {
    max-width: 16.66667%;
    flex-basis: 16.66667%;
  }
}
@media (min-width: 1610px) {
  .flex-wrap230 {
    max-width: 14.28571%;
    flex-basis: 14.28571%;
  }
}
@media (min-width: 1840px) {
  .flex-wrap230 {
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
}
@media (min-width: 2070px) {
  .flex-wrap230 {
    max-width: 11.11111%;
    flex-basis: 11.11111%;
  }
}
@media (min-width: 2300px) {
  .flex-wrap230 {
    max-width: 10%;
    flex-basis: 10%;
  }
}

.flex-wrap250 {
  flex-grow: 1;
  flex-basis: 250px;
  max-width: 100%;
}
@media (min-width: 500px) {
  .flex-wrap250 {
    max-width: 50%;
    flex-basis: 50%;
  }
}
@media (min-width: 750px) {
  .flex-wrap250 {
    max-width: 33.33333%;
    flex-basis: 33.33333%;
  }
}
@media (min-width: 1000px) {
  .flex-wrap250 {
    max-width: 25%;
    flex-basis: 25%;
  }
}
@media (min-width: 1250px) {
  .flex-wrap250 {
    max-width: 20%;
    flex-basis: 20%;
  }
}
@media (min-width: 1500px) {
  .flex-wrap250 {
    max-width: 16.66667%;
    flex-basis: 16.66667%;
  }
}
@media (min-width: 1750px) {
  .flex-wrap250 {
    max-width: 14.28571%;
    flex-basis: 14.28571%;
  }
}
@media (min-width: 2000px) {
  .flex-wrap250 {
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
}
@media (min-width: 2250px) {
  .flex-wrap250 {
    max-width: 11.11111%;
    flex-basis: 11.11111%;
  }
}

.flex-wrap270 {
  flex-grow: 1;
  flex-basis: 270px;
  max-width: 100%;
}
@media (min-width: 540px) {
  .flex-wrap270 {
    max-width: 50%;
    flex-basis: 50%;
  }
}
@media (min-width: 810px) {
  .flex-wrap270 {
    max-width: 33.33333%;
    flex-basis: 33.33333%;
  }
}
@media (min-width: 1080px) {
  .flex-wrap270 {
    max-width: 25%;
    flex-basis: 25%;
  }
}
@media (min-width: 1350px) {
  .flex-wrap270 {
    max-width: 20%;
    flex-basis: 20%;
  }
}
@media (min-width: 1620px) {
  .flex-wrap270 {
    max-width: 16.66667%;
    flex-basis: 16.66667%;
  }
}
@media (min-width: 1890px) {
  .flex-wrap270 {
    max-width: 14.28571%;
    flex-basis: 14.28571%;
  }
}
@media (min-width: 2160px) {
  .flex-wrap270 {
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
}
@media (min-width: 2430px) {
  .flex-wrap270 {
    max-width: 11.11111%;
    flex-basis: 11.11111%;
  }
}

.flex-wrap300 {
  flex-grow: 1;
  flex-basis: 300px;
  max-width: 100%;
}
@media (min-width: 600px) {
  .flex-wrap300 {
    max-width: 50%;
    flex-basis: 50%;
  }
}
@media (min-width: 900px) {
  .flex-wrap300 {
    max-width: 33.33333%;
    flex-basis: 33.33333%;
  }
}
@media (min-width: 1200px) {
  .flex-wrap300 {
    max-width: 25%;
    flex-basis: 25%;
  }
}
@media (min-width: 1500px) {
  .flex-wrap300 {
    max-width: 20%;
    flex-basis: 20%;
  }
}
@media (min-width: 1800px) {
  .flex-wrap300 {
    max-width: 16.66667%;
    flex-basis: 16.66667%;
  }
}
@media (min-width: 2100px) {
  .flex-wrap300 {
    max-width: 14.28571%;
    flex-basis: 14.28571%;
  }
}
@media (min-width: 2400px) {
  .flex-wrap300 {
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
}

.flex-wrap650 {
  flex-grow: 1;
  flex-basis: 650px;
  max-width: 100%;
}
@media (min-width: 1300px) {
  .flex-wrap650 {
    max-width: 50%;
    flex-basis: 50%;
  }
}
@media (min-width: 1950px) {
  .flex-wrap650 {
    max-width: 33.33333%;
    flex-basis: 33.33333%;
  }
}
@media (min-width: 2600px) {
  .flex-wrap650 {
    max-width: 25%;
    flex-basis: 25%;
  }
}
@media (min-width: 3250px) {
  .flex-wrap650 {
    max-width: 20%;
    flex-basis: 20%;
  }
}

.placeholder::-webkit-input-placeholder {
  font-family: 'Avenir-Roman', 'Lato';
  font-size: '12px';
  color: '#777';
}

.placeholder::-moz-placeholder {
  font-family: 'Avenir-Roman', 'Lato';
  font-size: '12px';
  color: '#777';
}

.placeholder::-ms-input-placeholder {
  font-family: 'Avenir-Roman', 'Lato';
  font-size: '12px';
  color: '#777';
}

.placeholder::-o-input-placeholder {
  font-family: 'Avenir-Roman', 'Lato';
  font-size: '12px';
  color: '#777';
}

.popup_backdrop {
  z-index: 300;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  bottom: 0px;
  left: 0px;
  right: 0px;
  -webkit-animation: fade 0.3s;
  animation: fade 0.3s;
}

.notification_snapshot_container:hover {
  background-color: #111111;
}

.notification_snapshot_container .notification_text {
  text-align: left;
  width: 520px;
  left: 20px;
  position: relative;
  top: 15px;
  height: 50px;
  letter-spacing: 1px;
  font-family: 'Avenir-Medium', 'Lato';
  font-weight: 500;
  font-size: 12px;
}

.notification_snapshot_container .notification_text--selected {
  text-align: left;
  width: 520px;
  left: 20px;
  position: relative;
  top: 15px;
  height: 50px;
  letter-spacing: 1px;
  font-family: 'Avenir-Medium', 'Lato';
  font-weight: 500;
  font-size: 12px;
  color: #888888;
}

.notification_snapshot_container .notification_date {
  left: 100px;
  height: 20px;
  color: #555555;
  top: 55px;
  position: absolute;
  font-family: 'Avenir-Heavy', 'Lato';
  font-weight: 800;
}

@-webkit-keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.header_container.header_container--white {
  background-color: white;
  z-index: 2000;
  color: black;
}

.header_container.header_container--video {
  z-index: 2000;
  border-width: 0px;
}

.notification_in_sidebar {
  float: left;
  height: 15px;
  padding-left: 0px;
  padding-right: 4px;
  margin-left: 4px;
  border-radius: 6px;
  width: 8px;
  height: 8px;
  background-color: #fa4726;
  border: 2px solid #fa4726;
}

#header_button_dropdown {
  position: absolute;
  top: -22.5px;
}

#header_button_dropdown:hover .dropwdown {
  display: initial;
}

.dropwdown {
  list-style: none;
  padding-left: 0px;
  color: white;
  display: none;
}
.dropwdown a {
  color: inherit;
  text-decoration: none;
}
.dropwdown li {
  background: #111;
  width: 138px;
  height: 45px;
  line-height: 45px;
  border: 1px solid #666;
  color: white;
}
.dropwdown li:hover {
  background: #333;
  cursor: pointer;
}

.notification {
  height: 15px;
  position: absolute;
  right: -10px;
  padding-left: 0px;
  padding-right: 4px;
  top: 12px;
  border-radius: 6px;
  width: 8px;
  height: 8px;
  background-color: #fa4726;
  border: 2px solid #fa4726;
}
.notification .notification_number {
  margin-top: -23px;
  font-size: 12px;
  color: #fa4726;
  margin-left: 10px;
  text-align: left;
}

.header_container {
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.02);
  background-color: white;
  z-index: 100;
  width: 100%;
  height: 45px;
  line-height: 45px;
  flex-direction: row;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #ccc;
  display: flex;
}
.header_container .header_button_container {
  position: relative;
}
.header_container .header_button_container:hover {
  cursor: pointer;
}
.header_container .header_button_container:hover .header_button {
  color: #fa4726;
}
.header_container .header_button_container .header_button--no_icon .header_button__label {
  padding-left: 0px;
}
.header_container .header_button_container .header_button {
  display: flex;
  text-decoration: none;
  color: #ffffff;
  margin-left: 30px;
  float: right;
  letter-spacing: 2px;
  font-size: 11px;
  cursor: pointer;
  font-family: 'Avenir-Medium', 'Lato';
  font-weight: 500;
  letter-spacing: 2;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.header_container .header_button_container .header_button .header_button__label--white {
  color: black;
}
.header_container .header_button_container .header_button .header_button__label {
  padding-left: 27px;
  min-width: 50px;
  height: 44px;
  text-align: center;
  color: #222;
}
.header_container .header_button_container .header_button .header_button_notification {
  position: absolute;
  right: -10px;
  color: #fa4726;
  border-color: #fa4726;
  border-width: 2px;
  border-style: solid;
  line-height: 15px;
  border-radius: 8px;
  width: 16px;
  letter-spacing: 1px;
  font-family: 'Avenir-Heavy', 'Lato';
  font-weight: 800;
  text-align: center;
  font-size: 10px;
  height: 16px;
  top: 5px;
}

.hamburger_button.hamburger_button--white {
  background-image: url('images/icons/icon-hamburger-black.png');
}

.hamburger_button {
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-left: 15px;
  margin-top: 12px;
  margin-right: 0px;
  z-index: 200;
  background-image: url('images/icons/icon-hamburger.png');
  background-repeat: no-repeat;
  background-size: 60% 60%;
}

.hamburger_button:hover {
  background-image: url('images/icons/icon-hamburger-selected.png');
}

.search_box_input.search_box_input--white {
  border: 1px solid white;
  background-color: #f7f7f7;
  color: black;
}

.search_box_input:focus {
  border-color: white;
  outline: none;
}

.search_box_input::-webkit-input-placeholder {
  color: white;
  line-height: 45px;
  height: 45px;
}

.scLogo.scLogo--white {
  color: black;
}

.scLogo {
  cursor: pointer;
  text-align: left;
  margin-left: 37px;
  line-height: 30px;
  font-family: 'Avenir-Roman', 'Lato';
  font-weight: 400;
  font-size: 1rem;
  float: left;
  letter-spacing: 8px;
  padding-right: 15px;
  text-decoration: none;
  color: white;
}

.header_button {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.scLogo:hover,
.header_button:hover {
  color: #fa4726;
  text-decoration: none;
}

html {
  -webkit-tap-highlight-color: transparent;
}

* {
  box-sizing: border-box;
  margin: 0px;
}

.App {
  text-align: center;
}

img {
  vertical-align: middle;
}

img {
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

a {
  text-decoration: none;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mobile_download_app_popup {
  display: block;
  z-index: 1000;
  width: 100%;
  background-color: white;
  height: 60px;
  position: fixed;
  top: -60px;
  line-height: 50px;
  border-bottom: 1px solid white;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -o-transition: all 0.1 linear;
  -ms-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.mobile_download_app_popup_enabled {
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  transition: all 0.1s linear;
  display: block;
  z-index: 1000;
  width: 100%;
  background-color: black;
  height: 60px;
  position: fixed;
  top: 0px;
  line-height: 50px;
  border-bottom: 1px solid white;
}

.bxslider-single-item {
  width: 100% !important;
  transform: none !important;
}
.create_job_OLD .bxslider-single-item {
  padding: 0;
}

.bxslider-single-item div:nth-child(2) {
  position: absolute !important;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.loader {
  font-size: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ffffff;
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  animation: load3 1.4s infinite linear;
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #ffffff;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: #777;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@keyframes load3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
